import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import {ISido} from '../features/Sido/slice';

export const getSigungu = () => {
  return axios.get(`${API_URI}/sigungus?_limit=10000`);
};

export const getIdSigungu = (list: ISido) => {
  return axios.get(`${API_URI}/sigungus?_limit=10000&id=${list.id}`);
};
