import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {CircularProgress, IconButton} from '@material-ui/core';
import Xbutton from '../image/XButton.png';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {MobileWeb} from './Mobile/Breakpoints';
import {API_URI} from '~/api/apiServer';
import {useDispatch, useSelector} from 'react-redux';
import {login, VERIFY} from '~/features/Verify/slice';
import {RootState} from '~/app/rootReducers';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  adpage: {
    position: 'absolute',
    width: '400px',
    background: '#fff',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: 'auto',
      // height: '178px',
    },
  },

  topp: {
    fontSize: '18px',
    fontFamily: 'Noto Sans Bengali',
    color: '#333',
    margin: '0',
    padding: '24px 26px 32px',
    fontWeight: 'bold',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '11px',
      padding: '12px 12px 10px',
    },
  },

  adMiddle: {
    background: '#F0EFEF',
    justifyContent: 'center',
    // padding: '10px 0px 10px 15px',
    color: '#FE4645',
    bottom: '0',
    // margin: '0 -20px',
    width: '400px',
    height: 'fit-content',
    fontSize: '12px',
    position: 'absolute',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '100%',
      height: 'auto',
      minHeight: 72,
      display: 'flex',
      flexDirection: 'column',
    },
  },

  adMiddleP: {
    padding: '17px 21px 6px',
    margin: '0',
    color: '#FE4645',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '7px 12px 0px',
      fontSize: '7px',
      color: '#FE4645',
    },
  },

  adMiddleP2: {
    padding: '0px 21px 12px',
    margin: '0',
    color: '#FE4645',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '6px 12px 7px',
      fontSize: '7px',
      color: '#FE4645',
    },
  },

  userp: {
    color: '#333',
    textDecorationLine: 'none',
    padding: '0 10px',
    float: 'left',
    border: '1px solid #ccc',
    background: '#FFF',
    borderRadius: '0',
    fontSize: '14px',
    display: 'inline',
    height: '28px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '9px',
      border: 0,
      borderRadius: '0',
      padding: '0',
    },
  },

  modalbtn: {
    color: '#fff',
    background: '#FB2E2E',
    width: '131px',
    height: '38px',
    borderRadius: '0',
    fontWeight: 'normal',
    '&:hover': {
      background: '#FB2E2E',
    },
    margin: '0 22px 16px 0',
    fontSize: '12px',
    minHeight: 30,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      background: '#FE4645',
      // margin: 'auto',
      width: 110,
      height: 34,
      padding: '0',
      fontSize: '10px',
    },
  },

  modalInputN: {
    height: '15px',
    width: '193px',
    padding: '10px 8px 9px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minHeight: '30px',
      width: '130px',
      padding: '0px',
      margin: 'auto',
    },
  },

  modalInputR: {
    height: '15px',
    width: '193px',
    // margin: '0 13px 16px 21px',
    padding: '10px 8px 9px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minHeight: '30px',
      width: '130px',
      padding: '0',
    },
  },

  modalpr: {
    display: 'flex',
    // padding: '5px 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  divmodal: {
    // padding: '40px 0 45px',
    height: '100%',
    minHeight: 200,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minHeight: 170,
    },
  },

  xbtn: {
    position: 'absolute',
    right: '1.2em',
    top: '20px',
    width: '14px',
    height: '14px',
    padding: 0,
    display: 'inline',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  webBtn: {
    position: 'absolute',
    right: '1.2em',
    top: '20px',
    width: '14px',
    height: '14px',
    padding: 0,
    display: 'contents',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  mobileBtn: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '10px',
      padding: 0,
      display: 'inline',
    },
  },

  mobileDiv: {
    display: 'block',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'block',
      height: '22px',
    },
  },

  phoneNumberDiv: {
    margin: '0 13px 16px 21px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      // margin: 'auto',
    },
  },

  errorMessage: {
    fontSize: '12px',
    color: '#FE4645',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  buttonProgress: {
    color: 'gray',
  },
}));

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export default function NewitemModal(props: ModalProps) {
  const [inputValue, setInputValue] = useState('');
  const [checkNumber, setCheckNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const {t} = useTranslation();
  // Phone Number
  const {open, onClose} = props;
  const handlePress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b -]{0,11}$/;
    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  //
  // api

  const handlePhoneInput = async () => {
    setPhoneLoading(true);
    try {
      await axios
        .post(`${API_URI}/verifies`, {
          phone: `${inputValue}`,
        })
        .then((response) => {
          if(response.data.authStatus === false){
            window.alert(t('이미 휴대폰 인증을 시도하셨습니다. 30분 뒤에 다시 시도해 주세요.'));
          } else {
            setPhoneError('');
            window.alert(t('인증번호가 발송되었습니다.'));
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      setPhoneLoading(false);
    }
  };

  const dispatch = useDispatch();

  const {loading} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (!loading) {
      onClose();
    }
  }, [loading, onClose]);

  const handleLogin = useCallback(() => {
    dispatch(
      login({
        phone: `${inputValue}`,
        code: `${checkNumber}`,
      }),
    );
  }, [dispatch, inputValue, checkNumber]);

  const classes = useStyles();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState();
  // const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleOpen = () => {
    // setOpen(true);
    history.push('/myitem');
  };

  const handleClose = () => {
    onClose();
  };

  // const modalOpen = () => {
  //   setOpen(true);
  // };

  // console.log(loading);

  const body = (
    <div style={modalStyle} className={classes.adpage}>
      <div>
        <p className={classes.topp}>{t('전화번호 인증이 필요합니다.')}</p>
        <IconButton onClick={handleClose} className={classes.xbtn}>
          <img alt="Xbutton" src={Xbutton} />
        </IconButton>
      </div>
      <div className={classes.divmodal}>
        <div className={classes.modalpr}>
          {/* 전화번호 입력 */}
          <div className={classes.phoneNumberDiv}>
            <input
              placeholder={t('인증번호 받기')}
              className={classes.modalInputN}
              value={inputValue}
              onChange={handlePress}
              type="text"
            />
            {phoneError && (
              <div className={classes.errorMessage}>{phoneError}</div>
            )}
          </div>
          <div>
            <Button
              disabled={phoneLoading}
              className={classes.modalbtn}
              onClick={() => {
                handlePhoneInput();
              }}>
              <b className={classes.webBtn}>
                {phoneLoading ? (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : (
                  t('인증번호 받기')
                )}
              </b>
              <b className={classes.mobileBtn}>
                {phoneLoading ? (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : (
                  t('인증번호 받기')
                )}
              </b>
            </Button>
          </div>
          {/* 인증번호 확인 */}
        </div>
        <div className={classes.modalpr}>
          <div className={classes.phoneNumberDiv}>
            <input
              placeholder={t('인증번호 입력')}
              className={classes.modalInputR}
              value={checkNumber}
              // onChange={(e) => {
              // }}
              onChange={(e) => {
                setCheckNumber(e.target.value);
              }}
              type="text"
            />
            {/* {numberError && (
              <div className={classes.errorMessage}>{numberError}</div>
            )} */}
          </div>
          <div>
            <Button
              disabled={btnLoading}
              className={classes.modalbtn}
              onClick={() => {
                setBtnLoading(true);
                if (checkNumber.length === 6) {
                  handleLogin();
                  setBtnLoading(false);
                  // if (loading === true) {
                  //   window.alert(t('로그인 세션이 만료되었습니다_'));
                  // } else {
                  //   window.alert(t('로그인이 되었습니다_'));
                  //   window.location.reload();
                  // }
                } else {
                  window.alert(t('전화번호 혹은 인증번호를_'));
                  setBtnLoading(false);
                }
              }}>
              {btnLoading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : (
                t('확인')
              )}
            </Button>
          </div>
        </div>
      </div>

      <div className={classes.adMiddle}>
        <p className={classes.adMiddleP}>※ {t('본인 확인을 위한_')}</p>
        <p className={classes.adMiddleP2}>※ {t('휴대폰 인증을 받고_')}</p>
      </div>
    </div>
  );

  return (
    <div className={classes.mobileDiv}>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
      <Button onClick={handleOpen} className={classes.userp}>
        {t('내가 쓴 글')}
      </Button>
    </div>
  );
}
