import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import {MobileWeb} from '~/components/Mobile/Breakpoints';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loadPost, POST} from '~/features/Post/slice';
import {RootState} from '~/app/rootReducers';
import _ from 'lodash';
import moment from 'moment';
import {createSido, SIDO} from '~/features/Sido/slice';
import {createSigungu} from '~/features/Sigungu/slice';
import {VERIFY} from '~/features/Verify/slice';
import './style.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {LangCode, LOGIN, setLang} from '~/features/Login/slice';

const useStyles = makeStyles((theme) => ({
  layout: {
    fontSize: '14px',
    fontFamily: 'Noto Sans Bengali',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  textgroup: {
    borderBottom: '1px solid #979696',
    padding: '41px 0px 19px',
    maxWidth: 980,
    width: '100%',
    margin: '0 auto',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      padding: '23px 0px 9px',
    },
  },

  text: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 'bold',
    marginRight: '5px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  textpr: {
    fontSize: '14px',
    color: '#aaa',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  textAreaPr: {
    display: 'flex',
    justifyContent: 'normal',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      justifyContent: 'center',
    },
  },

  mobileAreaPr: {
    display: 'flex',
    justifyContent: 'normal',
    paddingLeft: '0',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      justifyContent: 'normal',
      // paddingLeft: '16px',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  pcArea: {
    display: 'flex',
    margin: '0 auto',
    maxWidth: 980,
    width: '100%',
    height: '40px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      display: 'flex',
      margin: '0 auto',
      height: '25px',
      // justifyContent: 'center',
    },
  },

  mobileArea: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'flex',
      padding: '218px 36px',
      justifyContent: 'center',
      margin: '0 auto',
    },
  },
  userNone: {
    padding: '218px 36px',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  mobileText: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      fontSize: '14px',
      margin: '0',
    },
  },
  userNoneText: {
    display: 'inline',
    fontSize: '14px',
    margin: '0',
    textAlign: 'center',
  },

  mobileConfirm: {
    fontSize: '14px',
    color: '#FE4645',
    marginRight: '20px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      color: '#FE4645',
      marginRight: '9px',
      minWidth: 45,
    },
  },

  mobileName: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 'bold',
    marginRight: '20px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      color: '#333',
      fontWeight: 'normal',
      marginRight: '3px',
    },
  },

  mobileTitle: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: 180,
    },
  },

  mobileSubName: {
    fontSize: '14px',
    color: '#333',
    fontWeight: 'bold',
    marginRight: '5px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      color: '#BDBDBD',
      fontWeight: 'normal',
      marginRight: '3px',
      minWidth: 170,
    },
  },
  paging: {
    margin: '15px 3.5px 120px 0px',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      // display: 'none',
      margin: '10px auto 50px',
    },
  },

  pagingMobile: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: ' 60px',
      margin: '10px 3.5px 0px 0px',
      display: 'flex',
    },
  },

  pagingPrev: {
    marginRight: '3.5px',
    padding: '0',
    width: '24.5px',
    height: '24.5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#DCD8D8',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  pagingNext: {
    padding: '0',
    width: '24.5px',
    height: '24.5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#DCD8D8',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  dateDiv: {
    minWidth: 40,
  },

  catDiv: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: '#aaa',
    minWidth: 140,
    [theme.breakpoints.up(MobileWeb.mobile)]: {},
  },
}));

const Myitem = () => {
  // API //
  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(createSido({id: 1}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(createSigungu());
  }, [dispatch]);

  // Sido & Sigungu ----------------------

  const {sidoList} = useSelector((state: RootState) => state[SIDO]) ?? {};
  const {user} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const {postList} = useSelector((state: RootState) => state[POST]) ?? {};
  const [files, setFiles] = useState<File[]>([]);
  const {jwt} = useSelector((state: RootState) => state[VERIFY]) ?? {};

  const {lang} = useSelector((root: RootState) => root[LOGIN]);

  useEffect(() => {
    dispatch(loadPost());
  }, [dispatch]);

  const userPost = useMemo(() => {
    return _.compact(
      _.sortBy(
        _.map(postList, function (o) {
          if (o.user?.id === user?.id) {
            return o;
          }
        }),
        (up) => moment(up?.updated_at),
      ).reverse(),
    );
  }, [postList, user]);

  const classes = useStyles();
  const history = useHistory();

  //pageNation //

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(20);

  const [pageNumberLimit, setpageNumberLimit] = useState(10);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (e: any) => {
    setcurrentPage(Number(e.target.id));
  };

  const pages = [];
  if (userPost) {
    for (let i = 1; i <= Math.ceil(userPost.length / itemsPerPage); i++) {
      pages.push(i);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    userPost && userPost.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={`${number}`}
          onClick={handleClick}
          className={`${currentPage === number ? 'active' : null}`}>
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const postFound = useMemo(() => {
    return currentItems;
    // return _.sortBy(currentItems, (up) => moment(up.updated_at)).reverse();
  }, [currentItems]);

  const renderData = (data: any[]) => {
    return (
      <>
        {_.map(data, (list) => {
          const sidoId = _.find(sidoList, function (o) {
            return `${o.code}` === list.sido_code;
          })?.name;

          const sigungu = _.find(sidoList, function (o) {
            return `${o.code}` === list.sido_code;
          })?.sigungus;

          const siggId = _.find(sigungu, function (o) {
            return `${o.code}` === list.sgg_code;
          })?.name;

          const post = _.find(postList, function (o) {
            return o.id === list.id;
          });

          const postId = _.find(postList, function (o) {
            return o.id === list.id;
          })?.id;

          const postBoard = _.find(postList, function (o) {
            return o.id === list.id;
          })?.board;

          let title = list?.title;
          if (lang === 'ch' && list?.title_ch) {
            title = list?.title_ch;
          }
          if (lang === 'ru' && list?.title_ru) {
            title = list?.title_ru;
          }
          if (lang === 'vi' && list?.title_vi) {
            title = list?.title_vi;
          }

          const handleInputItem = async () => {
            try {
              const formData = new FormData();
              for (let i = 0; i < files.length; i++) {
                formData.append(`files.files`, files[i], files[i].name);
              }

              formData.append(
                'data',
                JSON.stringify({
                  title: `${post?.title}`,
                  sido_code: `${post?.sido_code}`,
                  sgg_code: `${post?.sgg_code}`,
                  content: `${post?.content}`,
                  board: `${postBoard?.id}`,
                  user: `${user?.id}`,
                  cat1: `${post?.cat1}`,
                }),
              );
              await axios
                .put(`${API_URI}/posts/${postId}`, formData, {
                  headers: {
                    Authorization: `Bearer ${jwt}`,
                  },
                })
                .then(() => {
                  history.push('/myitem');
                })
                .catch(() => {});
            } catch (error) {}
          };

          return (
            <div className={classes.pcArea} key={list.id}>
              <div className={classes.mobileAreaPr}>
                <div className={classes.mobileConfirm}>
                  <p className={classes.cursor} onClick={handleInputItem}>
                    [{t('재등록')}]
                  </p>
                </div>
                <div className={clsx(classes.mobileName, classes.dateDiv)}>
                  <p
                    className={classes.cursor}
                    onClick={() => {
                      history.push(`${list.id}`);
                    }}>
                    {moment(list.updated_at).format('MM-DD')}
                  </p>
                </div>
                <div className={classes.mobileName}>
                  <p
                    className={clsx(classes.cursor, classes.mobileTitle)}
                    onClick={() => {
                      history.push(`${list.id}`);
                    }}>
                    {title}
                  </p>
                </div>
                <div className={classes.mobileSubName}>
                  <p
                    className={clsx(classes.cursor, classes.catDiv)}
                    onClick={() => {
                      history.push(`${list.id}`);
                    }}>
                    {list.cat1 ? (
                      <>
                        ({t(`${sidoId}`)} {t(`${siggId}`)})-{' '}
                        {t(`${list?.cat1}`)} - {t(`${postBoard?.name}`)}
                      </>
                    ) : sidoId ? (
                      <>
                        ({t(`${sidoId}`)} {t(`${siggId}`)}) -{' '}
                        {t(`${postBoard?.name}`)}
                      </>
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  // 번역

  const [headerLang, setHeaderLang] = useState<LangCode>();

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  return (
    <div className={classes.layout}>
      {/* Header */}
      <Header
        langProp={(headerLang: LangCode) => {
          setHeaderLang(headerLang);
        }}
      />
      {/* Header - END */}
      {/* Middle */}
      {/* Middle Top */}
      <div className={classes.textgroup}>
        <div className={classes.textAreaPr}>
          <span className={classes.text}>
            {t('총')} {user ? userPost?.length : 0}
            {t('개')}{' '}
          </span>
          <span className={classes.textpr}>
            ({t('3개월이 지난글은 삭제될_')})
          </span>
        </div>
      </div>
      {user ? (
        <div
          style={{
            maxWidth: 980,
            minWidth: 360,
            width: '100%',
            margin: '0 auto',
          }}>
          {postFound && renderData(postFound)}
        </div>
      ) : (
        <>
          <div className={classes.userNone}>
            <div className={classes.userNoneText}>{t('해당 분류에 대한_')}</div>
          </div>

          <div className={classes.mobileArea}>
            <div className={classes.mobileText}>{t('해당 분류에 대한_')}</div>
          </div>
        </>
      )}
      {user ? (
        <ul className={classes.paging}>
          <li>
            <button
              className={classes.pagingPrev}
              onClick={handlePrevbtn}
              disabled={currentPage === pages[0] ? true : false}>
              <ArrowBackIosIcon style={{fontSize: '14px', color: '#979696'}} />
            </button>
          </li>
          {/* {pageDecrementBtn} */}
          <div className="pageNum" style={{display: 'flex'}}>
            {renderPageNumbers}
          </div>
          {/* {pageIncrementBtn} */}

          <li>
            <button
              className={classes.pagingNext}
              onClick={handleNextbtn}
              disabled={currentPage === pages[pages.length - 1] ? true : false}>
              <ArrowForwardIosIcon
                style={{fontSize: '14px', color: '#979696'}}
              />
            </button>
          </li>
        </ul>
      ) : (
        ''
      )}
      <div style={{display: 'none'}}>
        {setpageNumberLimit.name}
        {setitemsPerPage.name}
        {setFiles.length}
      </div>
      <Footer />
    </div>
  );
};
export default Myitem;
