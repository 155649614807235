import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
// import ImageGallery from 'react-image-gallery';
import Report from './Modal/Report';
import {MobileWeb} from './Mobile/Breakpoints';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {loadPost, POST} from '~/features/Post/slice';
import {useParams, useHistory} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import {VERIFY} from '~/features/Verify/slice';
import NewitemModal from './Newitemmodal';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {LangCode, LOGIN, setLang} from '~/features/Login/slice';
import {useTranslation} from 'react-i18next';
import {Button} from '@material-ui/core';
import {Typography} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  // Header
  layout: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto 180px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      margin: '0 auto',
    },
  },

  path: {
    display: 'inline',
    fontSize: '16px',
    margin: '0px 0px 110px',
    width: '100%',
    maxWidth: 980,
    minWidth: 360,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      margin: '0 25px',
    },
  },

  aboutWrap: {
    position: 'absolute',
    padding: '30, 15, 50',
    lineHeight: '26px',
    top: 124,

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  headerName: {
    display: 'flex',
    fontSize: '12px',
    color: '#979696',
    padding: '0 43px 8px 0',
    width: 'auto',
    height: 'auto',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'flex',
      padding: '0 23px 0 0',
      fontSize: '12px',
    },
  },

  mainName: {
    display: 'flex',
    fontSize: '12px',
    color: '#979696',
    padding: '19px 43px 8px 0',
    width: '100%',
    height: 'auto',
    borderTop: '0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      borderTop: '1px solid #ccc',
      padding: '7px 0 0',
      maxWidth: 980,
      minWidth: 310,
    },
  },

  p: {
    fontSize: '14px',
    fontWeight: 'bold',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '10px',
    },
  },

  pContent: {
    fontSize: '14px',
    fontWeight: 'bold',
    paddingTop: '15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      padding: '15px 25px 0px',
    },
  },

  user: {
    display: 'flex',
    margin: 'auto',
    listStyle: 'none',
    textDecorationLine: 'none',
    fontSize: '12px',
    fontFamily: 'Gulim, Tahoma, Arial, Verdana, Helvertica, sans-serif',
    float: 'right',
    padding: '0 0px 0 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  userp: {
    color: '#333',
    textDecorationLine: 'none',
    padding: '0 10px',
    float: 'left',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  userpr: {
    background: '#ff3900',
    fontWeight: 'bold',
    color: '#fff',
    padding: '0 25px',
    margin: '0 0 0 20px',
    float: 'left',
    textDecorationLine: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  articlefoot: {
    display: 'block',
    padding: '10 15 30',
    borderTop: '1px solid #ccc',
    maxWidth: 980,
    width: '100%',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  artbtn: {
    minWidth: '94px',
    height: '25px',
    padding: '1 16 0',
    // border: '1px solid #979696',
    borderRadius: '0',
    margin: '19px 5px 19px 0',
    cursor: 'pointer',
    background: '#63C5DA',
    color: '#fff',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      // color: '#2196F3',
      // background: '#EDF7FE',
      width: '45px',
      height: '30px',
      fontSize: '13px',
    },
    [theme.breakpoints.down(422)]: {
      minWidth: '70px',
    },
  },

  bitpic: {
    maxWidth: '475px',
    maxHeight: '330px',
    margin: '24px 0 8px',
    verticalAlign: 'middle',
    border: '1px solid #979696',
    // background: '#DCD8D8',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      maxWidth: '308px',
      maxHeight: '219px',
      margin: '9px 25px 10px',
    },
  },

  propsDiv: {
    display: 'flex',
    margin: '0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      flexWrap: 'wrap',
      margin: '0 25px',
    },
  },

  bitpicProp: {
    width: '54px',
    height: '38px',
    margin: '8px 6px 23px 0',
    border: '1px solid #979696',
    background: '#DCD8D8',
    cursor: 'pointer',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      margin: '8px 8px 16.8px 0',
    },
  },

  footPic: {
    width: '978px',
    height: '126px',
    background: '#DCD8D8',
    margin: '34px 0 32px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '311px',
      height: '161px',
    },
  },

  smallpic: {
    width: '70%',
    display: 'block',
    listStyleType: 'disc',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  // Modal
  modalbox: {
    display: 'block',
    width: '100%',
    margin: '0 auto',
    position: 'absolute',
    padding: '0 0 20px',
    paddingRight: '20px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  modaltop: {
    position: 'absolute',
    height: 38,
    lineHeight: 38,
    padding: '0 10',
    backgroundColor: '#eee',
    borderBottom: '1px solid #ccc',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  footA: {
    fontSize: '14px',
    color: '#979696',
    minWidth: 310,
    width: '90%',
    display: 'flex',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      color: '#979696',
      margin: '0 auto',
    },
  },

  contentName: {
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '46px 0px 0px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      margin: '18px 0 9px',
    },
  },

  contentPage: {
    margin: '0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
      margin: '0 25px',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    maxWidth: '800px',
    // maxHeight: '800px',
    width: '100%',
    cursor: 'poninter',
  },

  modalImg: {
    width: '100%',
    cursor: 'poninter',
    // height: '100%',
  },

  btnDiv: {
    display: 'flex',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginLeft: 25,
    },
    [theme.breakpoints.down(422)]: {
      marginLeft: 0,
      justifyContent: 'center',
    },
  },

  noticeDiv: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 15px',
    },
  },
}));

interface ChildProps {
  id?: any;
}

const NewContent = (props: ChildProps) => {
  const history = useHistory();
  const classes = useStyles();
  const {id} = useParams<{id: string}>();
  const {t} = useTranslation();

  const dispatch = useDispatch();

  const {lang} = useSelector((state: RootState) => state[LOGIN]);

  const {postList} = useSelector((state: RootState) => state[POST]) ?? {};
  const {jwt} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const {user} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const [openModal, setOpenModal] = React.useState(false);

  const pageChange = () => {
    history.goBack();
  };

  const deleteButton = async () => {
    try {
      await axios
        .delete(`${API_URI}/posts/${id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then(() => {
          history.goBack();
        })
        .catch(() => {});
    } catch (error) {}
  };

  const confirmButton = () => {
    history.push(`/confirm/${id}`);
  };

  useEffect(() => {
    dispatch(loadPost());
  }, [dispatch]);

  const post = useMemo(() => {
    return _.find(postList, function (o) {
      return `${o.id}` === id;
    });
  }, [postList, id]);

  const board = useMemo(() => {
    return post?.board;
  }, [post]);

  // const board = useMemo(() => {
  //   return _.find(post?.board, function (o) {
  //     return o;
  //   });
  // }, [post]);

  const checkId = useMemo(() => {
    return _.find(postList, function (o) {
      return `${o.id}` === id;
    })?.user?.id;
  }, [postList, id]);

  const checkUser = useMemo(() => {
    if (checkId === user?.id) {
      return true;
    } else {
      return false;
    }
  }, [checkId, user]);

  const postFiles = useMemo(() => {
    return _.find(postList, function (o) {
      return `${o.id}` === id;
    })?.files;
  }, [postList, id]);

  const filesUrl = useMemo(() => {
    return _.find(postFiles)?.url;
  }, [postFiles]);

  const title = useMemo(() => {
    if (lang === 'ch' && post?.title_ch) {
      return post.title_ch;
    }
    if (lang === 'vi' && post?.title_vi) {
      return post.title_vi;
    }
    if (lang === 'ru' && post?.title_ru) {
      return post.title_ru;
    }
    return post?.title;
  }, [post, lang]);

  const content = useMemo(() => {
    if (lang === 'ch' && post?.content_ch) {
      return post.content_ch;
    }
    if (lang === 'vi' && post?.content_vi) {
      return post.content_vi;
    }
    if (lang === 'ru' && post?.content_ru) {
      return post.content_ru;
    }
    return post?.content;
  }, [post, lang]);

  const [imgValue, setImgValue] = useState(`${filesUrl}`);
  const [imgOpen, setImgOpen] = React.useState(false);
  const handleImgOpen = () => {
    setImgOpen(true);
  };

  const handleImgClose = () => {
    setImgOpen(false);
  };

  // 번역

  const [headerLang, setHeaderLang] = useState<LangCode>();

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  return (
    <div>
      {/* Hader */}
      <Header
        langProp={(headerLang: LangCode) => {
          setHeaderLang(headerLang);
        }}
      />
      {/* Header - END */}
      {/* main START */}
      <div className={classes.layout}>
        <div className={classes.contentPage}>
          <div className={classes.contentName}>{title}</div>
          <div className={classes.mainName}>
            <div className={classes.headerName}>
              <div>{t('등록일')} : </div>
              <div> {moment(post?.updated_at).format('MM-DD')}</div>
            </div>
            <div className={classes.headerName}>
              <div>{t('글번호')} : </div>
              <div> {post?.id}</div>
            </div>
            <div className={classes.headerName}></div>
          </div>
        </div>
        <div className={classes.articlefoot} />
        {filesUrl !== undefined ? (
          <div style={{cursor: 'pointer'}}>
            <img
              alt="Main"
              src={`${imgValue}`}
              className={classes.bitpic}
              onClick={handleImgOpen}
            />
          </div>
        ) : (
          ''
        )}
        <div className={classes.propsDiv}>
          {_.map(postFiles, (files, index) => {
            return (
              <img
                alt="Sub"
                key={index}
                src={`${files.url}`}
                className={classes.bitpicProp}
                onClick={() => {
                  setImgValue(`${files.url}`);
                }}
              />
            );
          })}
        </div>

        <div className={classes.path}>
          <div className={classes.pContent}>
            <p style={{whiteSpace: 'pre-line'}}>{content}</p>
          </div>
          {board?.id === 1 || board?.id === 2 ? (
            <div className={classes.pContent}>
              <p>
                {t('연락처')} : {post?.user?.username?.substring(0, 3)}-
                {post?.user?.username?.substring(3, 7)}-
                {post?.user?.username?.substring(7)}
              </p>
              <p>{t('구인구직에서 보았다고 말씀하세요.')}</p>
            </div>
          ) : (
            <></>
          )}

          <div className={classes.articlefoot} />

          <div className={classes.noticeDiv}>
            <div
              style={{
                display: 'flex',
                background: '#feb',
                flexDirection: 'column',
                padding: '20px 32px',
                marginBottom: 15,
                marginTop: 10,
              }}>
              <Typography fontSize={14} fontWeight={'bold'} paddingBottom={1}>
                {t('공지사항')}
              </Typography>
              <Typography fontSize={14} fontWeight={'bold'} color={'#FF6056'}>
                {t('연령차별_')}
              </Typography>
            </div>
          </div>

          <div className={classes.articlefoot} />

          <div className={classes.btnDiv}>
            <div style={{display: 'flex'}}>
              <Report />
              <Button className={classes.artbtn} onClick={pageChange}>
                {t('목록')}
              </Button>
            </div>

            {checkId && user?.id && checkUser ? (
              <div style={{display: 'flex'}}>
                <Button
                  className={classes.artbtn}
                  onClick={() => {
                    confirmButton();
                  }}>
                  {t('수정')}
                </Button>
                <Button
                  className={classes.artbtn}
                  onClick={() => {
                    deleteButton();
                  }}>
                  {t('삭제')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className={classes.footA}>
            <div>※ {t('위 내용에 대한_')}</div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div style={{display: 'none'}}>
        <NewitemModal open={openModal} onClose={() => setOpenModal(false)} />
      </div>
      <Footer />
      {/* Footer - END */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={imgOpen}
        onClose={handleImgClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={imgOpen}>
          <div className={classes.paper} onClick={handleImgClose}>
            <img
              alt="Main"
              src={`${imgValue}`}
              className={classes.modalImg}
              onClick={handleImgOpen}
              sizes="contain"
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default NewContent;
