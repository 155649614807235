import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from '~/types';

// 여기선 시드 발생
export interface IVerify {
  phone: string;
}

// 여기선 토큰 발생
export interface CodeVerify {
  phone?: string;
  code?: string;
}
// ------------ Login ------------- //
export interface Login {
  phone: string;
  code: string;
}

export interface loginVerify {
  jwt: string;
  user: User;
}

// ------------- 여기까지 로그인 --------------- //
interface IState {
  login?: boolean;
  type?: string;
  loading?: boolean;
  code?: string;
  sid?: string;
  jwt?: string;
  user?: User;
  userInfo?: loginVerify;
  authorized?: boolean;
}

let initialState: IState = {
  login: false,
  loading: true,
  // loading: false,
  user: undefined,
};

const slice = createSlice({
  name: 'verify',
  initialState,
  reducers: {
    postSlice: (state, _action: PayloadAction<IVerify>) => {},
    // 여기서부터는 IVerify
    loadVerify: (state) => {
      state.loading = true;
      // state.loading = false;
    },
    successLoadVerify: (state, {payload}: PayloadAction<IVerify>) => {
      // state.loading = true;
      state.loading = false;
    },
    // 여기서부터는 CodeVerify
    saveCodeVerify: (state, {payload}: PayloadAction<CodeVerify>) => {
      // state.loading = true;
      state.loading = false;
    },
    successSaveCode: (state, {payload}: PayloadAction<string>) => {
      // state.loading = true;
      state.loading = false;
      state.jwt = payload;
    },
    // ------------------ LOGIN ------------------- //
    login: (state, _action: PayloadAction<Login>) => {
      // state.loading = true;
      state.loading = false;
    },
    successLogin: (state, action: PayloadAction<loginVerify>) => {
      // state.loading = true;
      state.loading = false;
      state.user = action.payload.user;
      state.jwt = action.payload.jwt;
      state.authorized = true;
    },
    // ---------------- 여기까지 로그인 -------------------- //
  },
});
export const {
  postSlice,
  loadVerify,
  successLoadVerify,
  saveCodeVerify,
  successSaveCode,
  login,
  successLogin,
} = slice.actions;
export const VERIFY = slice.name;
export default slice.reducer;
