import axios from 'axios';
import {LoginType} from '~/types';

const login = (param: LoginType) => {
  return axios.post(`/auth/local`, param);
};

export default {
  login,
};
