import {configureStore} from '@reduxjs/toolkit';
import {all} from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore, createTransform} from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import {LOGIN} from '~/features/Login/slice';
import rootReducer, {RootState} from './rootReducers';
import {watchLogin} from '~/features/Login/saga';
import {watchPost} from '~/features/Post/saga';
import {watchVerify} from '~/features/Verify/saga';
import {watchSido} from '~/features/Sido/saga';
import {watchSigungu} from '~/features/Sigungu/saga';
import {watchBoard} from '~/features/Board/saga';

const persistConfig = {
  key: 'root',
  whitelist: [LOGIN],
  storage,
  stateReconciler: hardSet,
  transforms: [
    createTransform((inboundState: any, key) => {
      if (key === LOGIN) {
        return {
          ...inboundState,
          authorized: false,
          lang: inboundState.lang ?? 'ko',
        };
      }
      return {
        ...inboundState,
      };
    }),
  ],
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

function* rootSaga() {
  yield all([
    watchSigungu(),
    watchSido(),
    watchLogin(),
    watchPost(),
    watchVerify(),
    watchBoard(),
  ]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware({});

  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [sagaMiddleware],
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return {store, persistor};
};

export type AppDipatch = ReturnType<typeof createStore>;
export default createStore;
