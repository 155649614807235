import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import {CodeVerify, IVerify, Login} from '~/features/Verify/slice';

export const postVerify = (postman: IVerify) => {
  return axios.post(`${API_URI}/verifies`, {
    ...postman,
  });
};

export const getSaveVerify = (body: CodeVerify) => {
  return axios.post(`${API_URI}/verifies/check`, {
    ...body,
  });
};

export const loginVerify = (body: Login) => {
  return axios.post(`${API_URI}/verifies/check`, {
    ...body,
  });
};
