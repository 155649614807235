import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ISigg {
  id?: number;
  name?: string;
  code?: string;
}
export interface ISido {
  name?: string;
  id?: number;
  code?: string;
  sigungus?: ISigg[];
}
interface IState {
  sidoList?: ISido[];
  loading?: boolean;
}

let initialState: IState = {
  //초기 값 : 원래는 전국이 들어가야되는데..
  loading: false,
  sidoList: undefined,
};

const slice = createSlice({
  name: 'sido',
  initialState,
  reducers: {
    // createSido: (state) => {
    createSido: (state, {payload}: PayloadAction<{id: number}>) => {
      state.loading = true;
    },
    successLoadSido: (state, {payload}: PayloadAction<ISido[]>) => {
      state.loading = false;
      state.sidoList = payload;
    },
    // successLoadId: (state, {payload}: PayloadAction<ISido[]>) => {
    //   state.loading = false;
    //   state.sidoList = payload;
    // }
  },
});
export const {createSido, successLoadSido} = slice.actions;
export const SIDO = slice.name;
export default slice.reducer;
