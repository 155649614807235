import React, {useEffect, useState} from 'react';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import {LangCode, setLang} from '~/features/Login/slice';
import {PrivacyPolicy} from '~/forms/page/PrivacyPolicy';


const About = () => {
  const [headerLang, setHeaderLang] = useState<LangCode>();

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  return (
    <div>
      <Header
        langProp={(headerLang: LangCode) => {
          setHeaderLang(headerLang);
        }}
      />

      <PrivacyPolicy sitename='114114구인구직' company="위너커머스" email='winnercm2024@gmail.com'/>

      <Footer />
    </div>
  );
};

export default About;
