// import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../../apis/Post';
import {
  // IPost,
  // postSlice,
  loadPost,
  successLoadPost,
  inputAd,
  successInputAd,
  // getPost,
  // successGetPost,
} from './slice';

// function* handleCreatePost({payload}: PayloadAction<IPost>) {
//   try {
//     const res = yield call(Api.createPost, payload);
//   } catch (err) {}
// }

// 슬라이스를 사용하면 밑의 함수가 실행 돼.

function* handleLoadPost() {
  try {
    const res = yield call(Api.getLoadPost);
    const response = res.data;

    if (res.status >= 200 && res.status < 300) {
      yield put(successLoadPost(response));
    }
  } catch (err) {}
}

function* handleInputAd() {
  try {
    const res = yield call(Api.getLoadAD);
    const response = res.data;

    if (res.status >= 200 && res.status < 300) {
      yield put(successInputAd(response));
    }
  } catch (err) {}
}

export function* watchPost() {
  // yield takeLatest(postSlice, handleCreatePost);
  yield takeLatest(loadPost, handleLoadPost);
  yield takeLatest(inputAd, handleInputAd);
}
