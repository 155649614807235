import {combineReducers} from '@reduxjs/toolkit';
import {reducer as formReducer} from 'redux-form';
import boardReducer, {BOARD} from '~/features/Board/slice';
import loginReduer, {LOGIN} from '~/features/Login/slice';
import postReducer, {POST} from '~/features/Post/slice';
import sidoReucer, {SIDO} from '~/features/Sido/slice';
import sigunguReducer, {SIGUNGU} from '~/features/Sigungu/slice';
import verifyReducer, {VERIFY} from '~/features/Verify/slice';
// import { connectRouter } from "connected-react-router";

const rootReducer = combineReducers({
  [BOARD]: boardReducer,
  [SIGUNGU]: sigunguReducer,
  [SIDO]: sidoReucer,
  [LOGIN]: loginReduer,
  [POST]: postReducer,
  [VERIFY]: verifyReducer,
  form: formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
