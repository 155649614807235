export const MobileWeb = {
  main: 1140,
  resion: 1348,
  employ: 1185,
  login: 879,
  write: 1104,
  mypage: 1024,
  topAd: 956,
  mobile: 956,
};
