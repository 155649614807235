export function getPagination(
  totalPage: number, // 전체 페이지 수
  currentPage: number, // 현재 페이지
  MAX_PAGE_COUNT: number, // 한 번에 표시할 최대 페이지 수
): number[] {
  // 현재 페이지가 속한 블록 계산 (페이지 그룹)
  const currentBlock = Math.floor((currentPage - 1) / MAX_PAGE_COUNT);
  // 현재 블록의 시작 페이지 계산
  const startPage = currentBlock * MAX_PAGE_COUNT + 1;
  // 현재 블록의 끝 페이지 계산 (totalPage를 초과하지 않도록)
  const endPage = Math.min(startPage + MAX_PAGE_COUNT - 1, totalPage);

  // 표시할 페이지 번호를 담을 배열 생성
  const pages: number[] = [];
  // 시작 페이지부터 끝 페이지까지 배열에 추가
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  // 페이지 배열 반환
  return pages;
}
