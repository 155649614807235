import {call, takeLatest, put} from 'redux-saga/effects';
import * as Api from '../../apis/Sigungu';
import {createSigungu, successLoadSigungu} from './slice';

function* handleGetSigungu() {
  try {
    const res = yield call(Api.getSigungu);
    const response = res.data;

    if (res.status >= 200 && res.status < 300) {
      yield put(successLoadSigungu(response));
    }
  } catch (err) {}
}

export function* watchSigungu() {
  yield takeLatest(createSigungu, handleGetSigungu);
}
