import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import {
  CircularProgress,
} from '@material-ui/core';


const AdsComponent = () => {

    const [getDataFromBackend, setDataFromBackend] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const getDataToBackend = async ()=> {
        try{
            await axios.get(`${API_URI}/posts?board=6`)
            .then(response=>{
                const posts: any[] = response.data;
                const files: any[] = [];
                posts.map((array) => {
                    const thisFile: any[] = array.files;
                    if(thisFile.length !== 0){
                        thisFile.map((file) => {
                            files.push(file.url);
                            return file;
                        });
                    }
                    return array;
                });
                setDataFromBackend(files);
                setLoading(false);
            });
        }catch(e){
            setLoading(false);
        }
    }

    useEffect(() => {
        getDataToBackend();
    },[]);

    return (
        <div className='bannerFrame'>
            { loading ? (
                <CircularProgress size={24} className='myButtonProgress' />
            ) : (
                getDataFromBackend.map((myImg, index) => (
                    <a href="./feedback" className='bannerLink' key={index}>
                        <img src={myImg} alt="114114구인구직 배너" className='bannerImgSource'/>
                    </a>
                ))
            )}    
        </div>
    );
}

export default AdsComponent;