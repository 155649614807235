import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import React from 'react';
export const KoreaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_70:653)">
          <path
            d="M18.9633 38.0063C29.4294 38.0063 37.9139 29.5219 37.9139 19.0557C37.9139 8.58961 29.4294 0.105133 18.9633 0.105133C8.49717 0.105133 0.0126953 8.58961 0.0126953 19.0557C0.0126953 29.5219 8.49717 38.0063 18.9633 38.0063Z"
            fill="url(#paint0_linear_70:653)"
          />
          <path
            d="M31.8271 31.92C38.9319 24.8152 38.9319 13.296 31.8271 6.19124C24.7223 -0.913554 13.2032 -0.913554 6.09837 6.19124C-1.00642 13.296 -1.00642 24.8152 6.09837 31.92C13.2032 39.0248 24.7223 39.0248 31.8271 31.92Z"
            fill="white"
          />
          <path
            d="M24.629 18.0829C22.8328 16.7808 20.2957 17.2165 18.9619 19.0557C17.6281 20.8949 15.091 21.3307 13.2948 20.0285C11.5076 18.7327 11.1301 16.2057 12.4398 14.3678C11.4898 15.6877 10.9287 17.3052 10.9287 19.0557C10.9287 23.4929 14.5248 27.0889 18.9619 27.0889C21.6485 27.0889 24.0248 25.7691 25.4827 23.7437C26.795 21.907 26.4162 19.3787 24.629 18.0829Z"
            fill="#1C67B2"
          />
          <path
            d="M18.9633 11.0226C16.2767 11.0226 13.9004 12.3424 12.4412 14.3678C11.1315 16.2045 11.5089 18.7328 13.2962 20.0286C15.0923 21.3307 17.6295 20.895 18.9633 19.0558C20.2971 17.2166 22.8342 16.7808 24.6303 18.083C26.4176 19.3788 26.7951 21.907 25.4853 23.7437C26.4353 22.4238 26.9965 20.8063 26.9965 19.0558C26.9965 14.6199 23.3991 11.0226 18.9633 11.0226Z"
            fill="#EE2B32"
          />
          <path
            d="M8.68963 7.33389L4.29834 13.909L5.45072 14.6786L9.842 8.10353L8.68963 7.33389Z"
            fill="#1D1D1B"
          />
          <path
            d="M7.04937 6.23721L2.65808 12.8123L3.81046 13.5819L8.20174 7.00685L7.04937 6.23721Z"
            fill="#1D1D1B"
          />
          <path
            d="M10.3317 8.43021L5.94043 15.0053L7.09281 15.7749L11.4841 9.19984L10.3317 8.43021Z"
            fill="#1D1D1B"
          />
          <path
            d="M3.80899 24.53L2.65662 25.2997L7.04791 31.8747L8.20028 31.1051L3.80899 24.53Z"
            fill="#1D1D1B"
          />
          <path
            d="M7.09219 22.3371L5.93982 23.1067L10.3311 29.6818L11.4835 28.9122L7.09219 22.3371Z"
            fill="#1D1D1B"
          />
          <path
            d="M7.85526 27.0344L6.70288 27.804L8.68958 30.7787L9.84196 30.0091L7.85526 27.0344Z"
            fill="#1D1D1B"
          />
          <path
            d="M5.4512 23.4341L4.29883 24.2037L6.28553 27.1784L7.43791 26.4088L5.4512 23.4341Z"
            fill="#1D1D1B"
          />
          <path
            d="M29.2362 7.33592L28.0839 8.10556L32.4751 14.6806L33.6275 13.911L29.2362 7.33592Z"
            fill="#1D1D1B"
          />
          <path
            d="M29.9987 12.0323L28.8463 12.8019L30.833 15.7766L31.9854 15.007L29.9987 12.0323Z"
            fill="#1D1D1B"
          />
          <path
            d="M27.5946 8.43227L26.4423 9.2019L28.429 12.1766L29.5813 11.407L27.5946 8.43227Z"
            fill="#1D1D1B"
          />
          <path
            d="M30.8776 6.2386L29.7252 7.00824L31.7119 9.98293L32.8643 9.21329L30.8776 6.2386Z"
            fill="#1D1D1B"
          />
          <path
            d="M33.2819 9.83901L32.1295 10.6086L34.1162 13.5833L35.2686 12.8137L33.2819 9.83901Z"
            fill="#1D1D1B"
          />
          <path
            d="M30.8333 22.3349L28.8466 25.3096L29.9989 26.0793L31.9856 23.1046L30.8333 22.3349Z"
            fill="#1D1D1B"
          />
          <path
            d="M28.4285 25.9356L26.4418 28.9103L27.5941 29.68L29.5808 26.7053L28.4285 25.9356Z"
            fill="#1D1D1B"
          />
          <path
            d="M32.4747 23.4323L30.488 26.407L31.6404 27.1766L33.6271 24.2019L32.4747 23.4323Z"
            fill="#1D1D1B"
          />
          <path
            d="M30.0703 27.0309L28.0836 30.0056L29.236 30.7752L31.2227 27.8005L30.0703 27.0309Z"
            fill="#1D1D1B"
          />
          <path
            d="M31.7114 28.1274L29.7247 31.1021L30.8771 31.8717L32.8638 28.897L31.7114 28.1274Z"
            fill="#1D1D1B"
          />
          <path
            d="M34.1156 24.528L32.1289 27.5027L33.2813 28.2724L35.268 25.2977L34.1156 24.528Z"
            fill="#1D1D1B"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_70:653"
            x1="28.4401"
            y1="35.4707"
            x2="9.48592"
            y2="2.64114"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.005618" stopColor="#CFCFCE" />
            <stop offset="0.3596" stopColor="#9C9C9B" />
            <stop offset="0.5427" stopColor="#A9A9A9" />
            <stop offset="0.6517" stopColor="#B3B3B3" />
            <stop offset="0.8652" stopColor="#B5B5B5" />
            <stop offset="1" stopColor="#DBDADA" />
          </linearGradient>
          <clipPath id="clip0_70:653">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
