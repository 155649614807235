import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import NewitemModal from './Newitemmodal';
import {MobileWeb} from './Mobile/Breakpoints';
import {RootState} from '~/app/rootReducers';
import {useDispatch, useSelector} from 'react-redux';
import MobileHeader from './Mobile/MobileHeader';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@material-ui/core/Select';
import {ListItemIcon} from '@material-ui/core';
import {KoreaIcon} from '../image/KoreaIcon';
// import {RussiaIcon} from '~/image/RussiaIcon';
// import {ChinaIcon} from '~/image/ChinaIcon';
// import {VietnamIcon} from '~/image/VietnamIcon';
import {LOGIN, setLang} from '~/features/Login/slice';

const useStyles = makeStyles((theme) => ({
  // Header
  topbar: {
    zIndex: 300,
    left: '0px',
    top: '0px',
    width: '100%',
    background: '#daebf0',
    borderBottom: '1px solid #ccc',
    padding: '6px 0',
    lineHeight: '28px',
    height: '46px',
    margin: '0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      background: '#daebf0',
      width: '100%',
      height: '36px',
      margin: '0 auto',
      padding: '0',
      minWidth: 360,
    },
  },

  layout: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto',
    // padding: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      padding: '0',
    },
  },

  path: {
    display: 'inline',
    float: 'left',
    fontWeight: 'bold',
    padding: '0',
    fontSize: '16px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0',
    },
  },

  logo: {
    fontFamily: 'Noto Serif KR',
    fontSize: '24px',
    textDecorationLine: 'none',
    color: '#333',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '18px',
      // padding: '5px 0px 5px 30px',
    },
  },

  logoTitle: {
    fontFamily: 'Times New Roman, Times serif',
    fontSize: '25px',
    fontWeight: 'normal',
    textDecorationLine: 'none',
    color: '#333',
    display: 'flex',
    margin: '0',
    alignSelf: 'center',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '16px',
      padding: '0',
      marginLeft: 10,
    },
  },

  logoInfo: {
    fontFamily: 'Times New Roman, Times serif',
    fontSize: '25px',
    fontWeight: 'normal',
    textDecorationLine: 'none',
    color: '#333',
    display: 'flex',
    margin: '0',
    alignSelf: 'center',
    alignItems: 'center',
    marginLeft: 10,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '16px',
      padding: '0',
    },
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      maxWidth: 140,
    },
  },

  logoName: {
    color: '#FF3900',
  },

  user: {
    display: 'flex',
    // margin: 'auto',
    listStyle: 'none',
    textDecorationLine: 'none',
    fontSize: '12px',
    fontFamily: 'Gulim, Tahoma, Arial, Verdana, Helvertica, sans-serif',
    // float: 'right',
    padding: '0 0px 0 0',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  userp: {
    color: '#333!important',
    padding: '5!important',
    border: '1px solid #ccc!important',
    background: '#FFF!important',
    height: '30px',
    borderRadius: '0!important',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  userpr: {
    background: '#ff3900!important',
    fontWeight: 'bold',
    color: '#fff!important',
    padding: '0!important',
    margin: '0!important',
    float: 'left',
    textDecorationLine: 'none',
    boarderRadius: 'none',
    width: '78px',
    height: '30px',
    borderRadius: '0!important',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  pageModal: {
    padding: '0 0 0 3px',
    alignSelf: 'flex-end',
    // marginLeft: '57px',
  },
  // Header - END

  headerMenu: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      float: 'right',
    },
  },

  selectMenu: {
    backgroundColor: '#fff',
    borderRadius: 0,
    position: 'relative',
    border: '1px solid #ccc',
    fontSize: '14px',
    padding: '0',
    maxWidth: '148px',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 0,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: '#fff',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  selectInput: {
    display: 'flex',
    padding: '0 24px 0 0',
    alignItems: 'center',
    borderBottom: 'none',
  },

  iconMobile: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '14',
    },
  },

  headerDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 2,
    paddingLeft: 16,
    [theme.breakpoints.between(557, 956)]: {
      // marginLeft: 120,
    },
  },
}));

interface HeaderProps {
  langProp?: any;
}

const Header = (props: HeaderProps) => {
  const dispatch = useDispatch();
  const {lang} = useSelector((root: RootState) => root[LOGIN]);
  const [open, setOpen] = React.useState(false);
  // const {t} = useTranslation();

  // const theme = useTheme() as Theme;
  // const tab = useMediaQuery(theme.breakpoints.down(557));
  // const mobile = useMediaQuery(theme.breakpoints.down(557));

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //

  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  // const location = useLocation();
  // const locationName = location.pathname.slice(1);

  return (
    <div className={classes.topbar}>
      <div className={classes.layout}>
        <div className={classes.headerDiv}>
          <div className={classes.path}>
            {/* <Link to="/" className={classes.logo}>
              8282
              {!mobile ? (
                <p className={classes.logoTitle}>{t('빨리빨리')}</p>
              ) : (
                ' '
              )}
              9297
              {mobile ? (
                <p className={classes.logoTitle}>Friend</p>
              ) : (
                <p className={classes.logoTitle}>{t('구인구직_')} Friend</p>
              )}
              {!tab && locationName === 'board' ? (
                <p className={classes.logoInfo}>
                  <ArrowForwardIosIcon
                    fontSize="small"
                    className={classes.iconMobile}
                  />{' '}
                  {t('정보등록')}
                </p>
              ) : (
                ''
              )}
            </Link> */}
            <Link to="/" className={classes.logo}>
              114114구인구직.com
            </Link>
          </div>
          <ul className={classes.user}>
            <li>
              <div>
                <FormControl>
                  <Select
                    className={classes.selectMenu}
                    classes={{
                      select: classes.selectInput,
                    }}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    disableUnderline
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    value={lang}
                    onChange={(e: any) => {
                      dispatch(setLang(e.target.value));
                      props.langProp(e.target.value);
                    }}>
                    <MenuItem value="ko">
                      <ListItemIcon>
                        <KoreaIcon />
                      </ListItemIcon>
                      한국어
                    </MenuItem>
                    {/* <MenuItem value="ru">
                      <ListItemIcon>
                        <RussiaIcon />
                      </ListItemIcon>
                      Russian
                    </MenuItem>
                    <MenuItem value="ch">
                      <ListItemIcon>
                        <ChinaIcon />
                      </ListItemIcon>
                      中國語
                    </MenuItem>
                    <MenuItem value="vi">
                      <ListItemIcon>
                        <VietnamIcon />
                      </ListItemIcon>
                      Vietnam
                    </MenuItem> */}
                  </Select>
                </FormControl>
              </div>
            </li>
          </ul>
          <div className={classes.headerMenu}>
            <MobileHeader langProp={(e: any) => props.langProp(e)} />
          </div>
        </div>
      </div>
      <div style={{display: 'none'}}>
        <NewitemModal open={openModal} onClose={() => setOpenModal(false)} />
      </div>
    </div>
  );
};
export default Header;
