import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ISigungu {
  name?: string;
  id?: string;
  code?: string;
}
// IState는 나오는 값을 저장하는 것 from.현주
interface IState {
  sigunguList?: ISigungu[];
  loading?: boolean;
}

let initialState: IState = {
  //초기 값 : 원래는 전국이 들어가야되는데..
  loading: false,
  sigunguList: undefined,
};

const slice = createSlice({
  name: 'sigungu',
  initialState,
  reducers: {
    createSigungu: (state) => {
      state.loading = true;
    },
    successLoadSigungu: (state, {payload}: PayloadAction<ISigungu[]>) => {
      state.loading = false;
      state.sigunguList = payload;
    },
  },
});
export const {createSigungu, successLoadSigungu} = slice.actions;
export const SIGUNGU = slice.name;
export default slice.reducer;
