import {call, takeLatest, put} from 'redux-saga/effects';
import * as Api from '../../apis/Sido';
import {createSido, successLoadSido} from './slice';

function* handleGetSido() {
  try {
    const res = yield call(Api.getSido);
    const response = res.data;

    if (res.status >= 200 && res.status < 300) {
      yield put(successLoadSido(response));
    }
  } catch (err) {}
}

export function* watchSido() {
  yield takeLatest(createSido, handleGetSido);
}
