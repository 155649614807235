import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import * as Api from '../../apis/Verify';
import {
  CodeVerify,
  saveCodeVerify,
  successSaveCode,
  Login,
  login,
  successLogin,
} from './slice';

function* handleSaveVerify({payload}: PayloadAction<CodeVerify>) {
  try {
    const response = yield call(Api.getSaveVerify, payload);
    // const response = res.data;

    if (response.status >= 200 && response.status <= 500) {
      yield put(successSaveCode(response.jwt));
    }
  } catch (err) {}
}

function* handleLogin({payload}: PayloadAction<Login>) {
  try {
    const res = yield call(Api.loginVerify, payload);

    if (res.status >= 200 && res.status < 300) {
      yield put(successLogin(res.data));
      window.alert(
        '로그인이 되었습니다. \n인증번호 유효시간은 발급 후 30분입니다.',
      );
    }
  } catch (err) {
    if (err.response.status === 404) {
      if (err.response.data.jwt !== undefined) {
        yield put(successLogin(err.response.data));
        window.alert(
          '로그인이 되었습니다. \n인증번호 유효시간은 발급 후 30분입니다.',
        );
      } else if (err.response.data.jwt === undefined) {
        window.alert(
          '로그인 세션이 만료되었습니다. \n인증번호를 다시 받아주세요.',
        );
        window.location.reload();
      }
    }
  }
}

export function* watchVerify() {
  // yield takeLatest(postSlice, handlePostVerify);
  yield takeLatest(saveCodeVerify, handleSaveVerify);
  yield takeLatest(login, handleLogin);
}
