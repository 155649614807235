import React, {useCallback, useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {makeStyles} from '@material-ui/core/styles';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import {MobileWeb} from '~/components/Mobile/Breakpoints';
import ReginSelect from '~/forms/page/Select/ReginSelect';
import Button from '@mui/material/Button';
import {API_URI} from '~/api/apiServer';
import {useParams, useHistory} from 'react-router-dom';
import BoardRadio from '~/features/Board/Component/boardRadio';
import {VERIFY} from '~/features/Verify/slice';
import _ from 'lodash';
import {loadPost, POST} from '~/features/Post/slice';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import NewitemModal from '~/components/Newitemmodal';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import {CircularProgress} from '@material-ui/core';
import {LangCode, LOGIN, setLang} from '~/features/Login/slice';
import {Typography} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  // Middle
  dlborder: {
    borderBottom: '1px solid #ccc',
    paddingBottom: '15px',
    marginBottom: '15px',
    display: 'block',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  middlepage: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto',
    padding: '15px 0 30px',
    fontSize: '14px',
    fontFamily: 'Noto Sans Bengali',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 320,
      padding: '15px 0px 30px',
    },
  },

  selectlist: {
    display: 'inline',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  selectinput: {
    margin: '0 15px 0 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  selectBox: {
    width: '118px',
    height: '20px',
    // background: "#fff",
    float: 'left',
    listStyle: 'none',
    marginRight: '10px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      float: 'none',
      width: 'auto',
    },
  },

  selectBoxP: {
    width: '118px',
    height: '20px',
    // background: "#fff",
    float: 'left',
    listStyle: 'none',
    marginRight: '10px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  selectBoxM: {
    width: '118px',
    height: '20px',
    // background: "#fff",
    float: 'left',
    listStyle: 'none',
    marginRight: '10px',
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      float: 'none',
      width: 'auto',
      display: 'flex',
    },
  },

  ptag: {
    display: 'flex',
    margin: '0 20px',
    float: 'left',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  litag: {
    marginRight: '10px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  selectprop: {
    width: '100%',
    height: '20px',
    lineHeight: '20px',
    fontSize: '13px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '22px',
      width: '159px',
      fontSize: '11px',
    },
  },

  middleul: {
    display: 'inline-flex',
    alignItems: 'center',
    marginBottom: '10px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  catUl: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'flex',
    },
  },

  middleMobile: {
    display: 'inline-flex',
    alignItems: 'normal',
    marginBottom: '10px',
    width: '100%',
    maxWidth: 980,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 320,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'normal',
      marginBottom: 0,
    },
  },

  middlename: {
    padding: '0 5px!important',
    minWidth: 314,
    height: '19px',

    [theme.breakpoints.up(MobileWeb.mobile)]: {
      maxWidth: 892,
      width: '100%',
    },
  },

  titleArea: {
    width: '902px',
    padding: '0!important',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '320px',
      height: '19px',
    },
  },

  middletext: {
    minWidth: 316,
    height: 267,
    resize: 'none',
    whiteSpace: 'pre-wrap',
    borderRadius: 'none',

    [theme.breakpoints.up(MobileWeb.mobile)]: {
      width: '100%',
      padding: '5px !important',
      height: 301,
      maxWidth: 894,
    },
  },

  textArea: {
    width: '100%',
    maxWidth: 960,
    height: '301px',
    resize: 'none',
    padding: '0px !important',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '322px',
      height: '267px',
    },
  },

  middlepic: {
    background: '#63C5DA !important',
    padding: '2px 15px !important',
    float: 'left',
    textDecorationLine: 'none !important',
    color: '#fff !important',
    borderRadius: '0px !important',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '30px !important',
      maxWidth: 130,
      width: '100% !important',
      padding: '4px 10px !important',
      fontSize: '9px !important',
    },
  },

  middleacc: {
    borderTop: '1px solid #ccc',
    listStyle: 'none',
    paddingTop: 15,
    boxShadow: 'none',
    marginBottom: 120,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      borderTop: '0',
      padding: '30px 15px 0px',
      marginBottom: 50,
    },
  },

  middleaccpr: {
    background: '#63C5DA !important',
    color: '#fff !important',
    padding: '2px 15px !important',
    float: 'left',
    textDecorationLine: 'none !important',
    display: 'inline !important',
    borderRadius: '0px !important',
    minWidth: 320,
    maxWidth: 980,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      background: '#63C5DA !important',
      height: '30px !important',
      padding: '0 !important',
      margin: '0 !important',
      width: '100%',
    },
  },

  spanStyle: {
    marginRight: 15,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginRight: 0,
      fontSize: '11px',
    },
  },

  catSpan: {
    marginRight: 15,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  // Middle - END
  // Mobile

  mobileDiv: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'block',
      marginBottom: '9px',
      padding: '0px 15px',
    },
  },

  titleDiv: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'block',
      marginBottom: '9px',
      padding: '0px 15px',
    },
  },

  webDiv: {
    display: 'inline',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  mobileLi: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
    },
  },

  mobileSelect: {
    display: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'inline',
      height: '22px',
      width: '100%',
      minWidth: 320,
      fontSize: '11px',
    },
  },

  uploadPicture: {
    padding: '0',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'flex',
      paddingBottom: 16,
    },
  },

  MobileLi: {
    padding: '0 0 0 45px',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0',
    },
  },

  pictureStyle: {
    marginRight: 15,
    display: 'inline',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
      alignItems: 'nomal',
    },
  },

  pictureUl: {
    display: 'inline-flex',
    paddingBottom: '18px',
    // alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'revert',
      alignItems: 'nomal',
      padding: '0px 15px',
    },
  },

  pictureDiv: {
    borderBottom: 'none',
    maxWidth: 960,
    minWidth: 320,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      borderBottom: '1px solid #ccc',
    },
  },

  mobileRegion: {
    display: 'flex',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'flex',
    },
  },

  focused: {
    '& > fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px!important',
      borderColor: '#3b3b3b!important',
      // border: '1px solid #3b3b3b!important',
    },
  },

  fileBox: {
    padding: '0',
    maxWidth: '24px',
    minWidth: '0px',
    height: '24px',
  },

  fileList: {
    padding: '10px 5px 0 0',
    display: 'inline-block',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      // display: 'inline-block',
      padding: '0 5px 15px 0',
    },
  },

  buttonProgress: {
    color: 'gray',
    position: 'absolute',
    top: '50%',
    left: '54%',
    marginTop: -12,
    marginLeft: -12,
  },

  loadingBox: {
    position: 'absolute',
  },

  loadingTrue: {
    width: 98,
    height: 28.5,
  },

  topad: {
    fontSize: '12px',
    fontFamily: 'Noto Sans Bengali',
    padding: '0px 15px',
    minWidth: 320,

    [theme.breakpoints.up(MobileWeb.mobile)]: {
      maxWidth: 980,
      padding: 0,
      paddingBottom: 15,
    },
  },

  topPage: {
    maxWidth: 980,
    width: '100%',
    left: '230px',
    background: '#fffcf9',
    borderTop: '0.5px solid #979696',
    borderBottom: '0.5px solid #979696',
    boxSizing: 'border-box',
    fontFamily: 'Noto Sans Bengali',
    fontSize: '13px',
    margin: '0 auto',
    padding: '15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 320,
      borderTop: '0',
      borderBottom: '0',
      padding: '10px 0 10px 5px',
    },
  },

  topAdDiv: {
    marginTop: '9px',
    alignItems: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      fontSize: '12px',
    },
  },

  notice: {
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      fontSize: 15,
      fontWeight: 'bold',
      marginBottom: 10,
      color: '#ff3900',
    },
  },

  mobileSelector: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'flex',
      padding: '10px 15px 0px',
    },

    [theme.breakpoints.down(527)]: {
      display: 'revert',
    },
  },

  langMobile: {
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      display: 'revert',
    },
  },

  noticeDiv: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 15px',
    },
  },

  postBtn: {
    [theme.breakpoints.up(MobileWeb.mobile)]: {
      paddingLeft: 5,
    },
  },
}));

const ConfirmItem = () => {
  const {t} = useTranslation();

  // ---------- api - Board ------------- //

  const [sido, setSido] = useState('');
  const [sigg, setSigg] = useState('');
  const [boardName, setBoardName] = useState('');
  const [loading, setLoading] = useState(false);

  // ------------ JWT ---------------------------- //

  const {jwt} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const {user} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const {postList} = useSelector((state: RootState) => state[POST]) ?? {};
  const {id} = useParams<{id: string}>();
  const dispatch = useDispatch();

  // --------------Cat----------------------------- //

  // ---------------------------------------------- //
  const post = useMemo(() => {
    return _.find(postList, function (o) {
      return `${o.id}` === id;
    });
  }, [postList, id]);

  // const postBoardId = useMemo(() => {
  //   return _.find(post?.board, function (o) {
  //     return o;
  //   });
  // }, [post]);

  const [files, setFiles] = useState<File[]>([]);
  const [postTitle, setPostTitle] = useState(`${post?.title}`);
  const [postContent, setPostContent] = useState(`${post?.content}`);
  const [openModal, setOpenModal] = React.useState(false);
  const [boardId, setBoardId] = useState('1');

  const [, setBoardValue] = useState('');
  const [cat1Value, setCat1Value] = useState(`${post?.cat1}`);

  const handleInputItem = async () => {
    setLoading(true);

    // 공고 관련 유효성 검사 분리
    if (parseInt(boardId) === 1 || parseInt(boardId) === 2) {
      if (sido === '') {
        window.alert(t('지역을 선택해주세요.'));
        setLoading(false);
        return;
      } else if (sigg === '') {
        window.alert(t('지역을 선택해주세요.'));
        setLoading(false);
        return;
      } else if (cat1Value === '') {
        window.alert(t('분류를 선택해주세요.'));
        setLoading(false);
        return;
      }
    }

    if (boardId === '') {
      window.alert(t('게시판을 선택해주세요.'));
      setLoading(false);
    } else if (postTitle === '') {
      window.alert(t('제목을 입력해주세요.'));
      setLoading(false);
    } else if (postContent === '') {
      window.alert(t('내용을 입력해주세요.'));
      setLoading(false);
    } else if (postTitle.length > 50) {
      window.alert(t('제목은 최대 50자를_'));
      setLoading(false);
    } else if (postContent.length < 30) {
      window.alert(t('내용을 상세하게 작성해주세요.'));
      setLoading(false);
    } else if (postContent.length > 2000) {
      window.alert(t('내용은 최대 2,000자를_'));
      setLoading(false);
    } else if (files.length > 8) {
      window.alert(t('이미지는 최대 8장까지_'));
      setLoading(false);
    } else if (jwt === undefined) {
      window.alert(t('인증번호가 만료되었습니다_'));
      window.location.reload();
      setLoading(false);
    } else {
      try {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append(`files.files`, files[i], files[i].name);
        }

        formData.append(
          'data',
          JSON.stringify({
            title: `${postTitle}`,
            sido_code: `${sido}`,
            sgg_code: `${sigg}`,
            content: `${postContent}`,
            board: `${boardId}`,
            user: `${user?.id}`,
            cat1: `${cat1Value}`,
          }),
        );

        await axios
          .put(`${API_URI}/posts/${id}`, formData, {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          })
          .then(() => {
            history.push('/myitem');
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFiles: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!e.target.files) return;
      let f = files;
      for (const file of e.target.files) {
        f = [...f, file];
      }
      setFiles(f);
    },
    [files],
  );
  //
  const history = useHistory();

  const classes = useStyles();

  // Category

  const cat1 = useMemo(() => {
    return (
      <>
        <option value="">{t('분류선택')}</option>
        <option value="외식⠂식음료">{t('외식⠂식음료')}</option>
        <option value="판매⠂매장관리">{t('판매⠂매장관리')}</option>
        <option value="상담⠂영업">{t('상담⠂영업')}</option>
        <option value="사무직">{t('사무직')}</option>
        <option value="서비스직">{t('서비스직')}</option>
        <option value="생산⠂건설">{t('생산⠂건설')}</option>
        <option value="IT⠂디자인">{t('IT⠂디자인')}</option>
        <option value="교육⠂강사">{t('교육⠂강사')}</option>
      </>
    );
  }, [t]);

  // 번역
  const {lang} = useSelector((root: RootState) => root[LOGIN]);

  const [headerLang, setHeaderLang] = useState<LangCode>();

  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  useEffect(() => {
    dispatch(loadPost());
  }, [dispatch]);

  useEffect(() => {
    setBoardId(boardId);
  }, [boardId]);

  return (
    <div>
      <Header
        langProp={(headerLang: LangCode) => {
          setHeaderLang(headerLang);
        }}
      />
      {/* Header - END */}
      <form className={classes.middlepage}>
        <BoardRadio
          LoadId={(boardId: string) => setBoardId(boardId)}
          LoadName={(boardName: string) => setBoardName(boardName)}
        />
        <div className={classes.mobileDiv}>
          <li className={classes.mobileLi}>
            <select
              className={classes.mobileSelect}
              name="select"
              id="select_value"
              onChange={(e) => {
                setBoardId(e.target.value);
              }}>
              <option value="0">{t('게시판선택')}</option>
              <option value="1">{t('구인공고')}</option>
              <option value="2">{t('구직공고')}</option>
              <option value="3">{t('중고나라')}</option>
              <option value="4">{t('부동산정보')}</option>
            </select>
          </li>
        </div>
        {/* 안내문구 */}
        {boardId && (
          <div className={classes.topad}>
            <div className={classes.topPage}>
              <div className={classes.topAdDiv}>
                <div className={classes.notice}>{t('주의사항')}</div>
                <div style={{maxWidth: 886}}>
                  {parseInt(boardId) === 1 || parseInt(boardId) === 2
                    ? t('허위 기재 및_2')
                    : t('허위 기재 및_')}
                </div>
                <div style={{marginTop: 10}}>{t('문제 소지 및_')}</div>
                <div style={{marginTop: 10}}>{t('문제 소지 및_2')}</div>
              </div>
            </div>
          </div>
        )}
        {parseInt(boardId) === 1 || parseInt(boardId) === 2 ? (
          <div className={classes.mobileSelector}>
            <ReginSelect
              loadSido={(sido: string) => setSido(sido)}
              loadSigg={(sigg: string) => setSigg(sigg)}
            />
            <div className={classes.catUl}>
              <div>
                <span className={classes.catSpan}>{t('분류')} :</span>
              </div>
              <li className={classes.selectBoxP}>
                <select
                  className={classes.selectprop}
                  onChange={(e) => {
                    setCat1Value(e.target.value);
                  }}
                  onClick={() => {
                    setBoardValue(`${boardId}`);
                  }}>
                  {cat1}
                </select>
              </li>
              <li className={classes.selectBoxM}>
                <select
                  className={classes.selectprop}
                  onChange={(e) => {
                    setCat1Value(e.target.value);
                  }}
                  onClick={() => {
                    setBoardValue(`${boardId}`);
                  }}>
                  {cat1}
                </select>
              </li>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={classes.titleDiv}>
          <div
            className={clsx(classes.middleMobile, {
              [classes.langMobile]: lang === 'ru' || lang === 'vi',
            })}>
            <div>
              <span className={classes.spanStyle}>{t('제목')} : </span>
            </div>
            <input
              className={classes.middlename}
              type="text"
              id="title_txt"
              name="title"
              value={postTitle}
              onChange={(e) => {
                setPostTitle(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.titleDiv}>
          <div
            className={clsx(classes.middleMobile, {
              [classes.langMobile]: lang === 'ru' || lang === 'vi',
            })}>
            <div>
              <span className={classes.spanStyle}>{t('내용')} :</span>
            </div>
            <textarea
              aria-multiline="true"
              style={{whiteSpace: 'pre-wrap'}}
              rows={11}
              className={classes.middletext}
              id="content_txt"
              name="content"
              value={postContent}
              onChange={(e) => {
                setPostContent(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={classes.noticeDiv}>
          <div
            style={{
              display: 'flex',
              background: '#feb',
              flexDirection: 'column',
              padding: '20px 32px',
              marginBottom: 15,
              marginTop: 10,
            }}>
            <Typography fontSize={14} fontWeight={'bold'} paddingBottom={1}>
              {t('공지사항')}
            </Typography>
            <Typography fontSize={14} fontWeight={'bold'} color={'#FF6056'}>
              {t('연령차별_')}
            </Typography>
          </div>
        </div>
        <div className={classes.pictureUl}>
          <div>
            <div className={classes.pictureStyle}>{t('사진')} : </div>
          </div>
          <div className={classes.pictureDiv}>
            <div>
              <label
                className={classes.uploadPicture}
                htmlFor="contained-button-file">
                <input
                  hidden
                  id="contained-button-file"
                  accept="image/*"
                  multiple
                  type="file"
                  name="files"
                  onChange={handleFiles}
                />
                <Button className={classes.middlepic} component="span">
                  {t('사진등록')}
                </Button>
              </label>
            </div>
            <div className={classes.fileList}>
              {_.map(files, (list, index) => {
                return (
                  <div>
                    <CloseIcon
                      style={{
                        color: '#ccc',
                        border: '1px  solid #ccc',
                        fontSize: '14px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        files.splice(index, 1);
                        setFiles(_.filter(files));
                      }}
                    />
                    {list.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.middleacc}>
          <li className={classes.MobileLi}>
            <div
              className={clsx(classes.postBtn, {
                [classes.loadingBox]: loading,
              })}>
              <Button
                className={clsx(classes.middleaccpr, {
                  [classes.loadingTrue]: loading,
                })}
                onClick={() => {
                  user ? handleInputItem() : setOpenModal(true);
                }}
                disabled={loading}>
                {!loading ? t('수정하기') : ''}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </li>
        </div>
      </form>
      <div style={{display: 'none'}}>
        {boardName}
        <NewitemModal open={openModal} onClose={() => setOpenModal(false)} />
      </div>
      <Footer />
    </div>
  );
};
export default ConfirmItem;
