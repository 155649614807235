import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import React from 'react';
export const RussiaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_70:484)">
          <path
            d="M19.0001 38.0367C29.5138 38.0367 38.0368 29.5137 38.0368 19C38.0368 8.48629 29.5138 -0.0367432 19.0001 -0.0367432C8.48641 -0.0367432 -0.0366211 8.48629 -0.0366211 19C-0.0366211 29.5137 8.48641 38.0367 19.0001 38.0367Z"
            fill="url(#paint0_linear_70:484)"
          />
          <path
            d="M0.767578 19.0558C0.767578 21.185 1.13238 23.2066 1.80244 25.1269H36.1215C36.7916 23.2066 37.1564 21.185 37.1564 19.0558C37.1564 16.9265 36.7916 14.9062 36.1215 12.9846H1.80371C1.13238 14.9062 0.767578 16.9265 0.767578 19.0558Z"
            fill="#3A4FA2"
          />
          <path
            d="M18.9632 0.842346C11.0415 0.842346 4.30158 5.91028 1.80371 12.9846H36.1228C33.6249 5.91155 26.885 0.842346 18.9632 0.842346Z"
            fill="white"
          />
          <path
            d="M18.9632 37.2692C26.885 37.2692 33.6249 32.2012 36.1228 25.1269H1.80371C4.30158 32.2012 11.0402 37.2692 18.9632 37.2692Z"
            fill="#ED1C24"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_70:484"
            x1="28.5202"
            y1="35.4893"
            x2="9.48001"
            y2="2.5107"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.005618" stopColor="#CFCFCE" />
            <stop offset="0.3596" stopColor="#9C9C9B" />
            <stop offset="0.5427" stopColor="#A9A9A9" />
            <stop offset="0.6517" stopColor="#B3B3B3" />
            <stop offset="0.8652" stopColor="#B5B5B5" />
            <stop offset="1" stopColor="#DBDADA" />
          </linearGradient>
          <clipPath id="clip0_70:484">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
