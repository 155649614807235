import ko from './translations/ko.json';
import ru from './translations/ru.json';
import ch from './translations/ch.json';
import vi from './translations/vi.json';

export const resources = {
  ko: {
    translation: ko,
  },
  ru: {
    translation: ru,
  },
  ch: {
    translation: ch,
  },
  vi: {
    translation: vi,
  },
} as const;
