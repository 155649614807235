import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import React from 'react';
export const VietnamIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_70:486)">
          <path
            d="M18.9671 38C29.4423 38 37.9341 29.5081 37.9341 19.0329C37.9341 8.5577 29.4423 0.0658569 18.9671 0.0658569C8.49185 0.0658569 0 8.5577 0 19.0329C0 29.5081 8.49185 38 18.9671 38Z"
            fill="#939598"
          />
          <path
            d="M18.9672 36.9803C28.8793 36.9803 36.9146 28.945 36.9146 19.0329C36.9146 9.12084 28.8793 1.08551 18.9672 1.08551C9.0551 1.08551 1.01978 9.12084 1.01978 19.0329C1.01978 28.945 9.0551 36.9803 18.9672 36.9803Z"
            fill="#D92827"
          />
          <path
            d="M18.9999 8.3587L21.4953 15.7383L29.4335 15.9435L23.2496 20.8936L25.4473 28.2162L19.0291 23.5815L12.5526 28.2162L14.863 20.8936L8.56641 15.9435L16.5084 15.6838L18.9999 8.3587Z"
            fill="#F7EC26"
          />
        </g>
        <defs>
          <clipPath id="clip0_70:486">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
