import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import {IPost} from '../features/Post/slice';

export const createPost = (postman: IPost) => {
  return axios.post(`${API_URI}/posts`, {
    ...postman,
  });
};

export const getLoadPost = () => {
  return axios.get(`${API_URI}/posts?_limit=10000`);
};

export const getLoadAD = () => {
  return axios.get(`${API_URI}/posts?_limit=10000`);
};
