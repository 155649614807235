import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {MobileWeb} from './Mobile/Breakpoints';
import {useTranslation} from 'react-i18next';
import {Typography} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  adbtn: {
    fontSize: '24px',
    textDecorationLine: 'none',
    display: 'inline-block',
    color: 'darkorange',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    '&:hover': {
      background: 'none',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 18px 0px 0px',
      margin: '0',
      fontSize: '20px',
    },
  },

  adbtnSub: {
    fontSize: '16px',
    display: 'inline-block',
    marginLeft: '16px',
    color: 'blue',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      background: 'none',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 18px 0px 0px',
      margin: '0',
      fontSize: '12px',
    },
  },

  adpage: {
    position: 'fixed',
    width: '320px',
    background: '#fff',
    border: '1px solid #eee',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adtop: {
    position: 'relative',
    background: '#63C5DA',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  topp: {
    lineHeight: '19px',
    color: '#FFF',
    margin: 'auto',
    padding: '8px 24px 7px',
    textDecoration: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  admiddlep: {
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  admiddle: {
    position: 'relative',
    width: '100%',
    minHeight: '77px',
    background: '#daebf0',
    justifyContent: 'center',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adbtnp: {
    textAlign: 'center',
    padding: '10px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adcbtn: {
    position: 'relative',
    alignItems: 'center',
    background: '#daebf0',
    borderRadius: '5px',
    fontSize: '11px',
    minWidth: 61,
    fontWeight: 'bold',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adLeft: {
    marginLeft: '24px',
    padding: '8px 24px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginLeft: '15px',
    },
  },

  adMar: {
    margin: '11px 0 11px 15px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adPMar: {
    padding: '10px 24px 0',
    margin: '0',
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },
  modalBody: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  flexLayout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default function Topadinfo() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState();
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.adpage}>
      <div className={classes.adtop}>
        <Typography className={classes.topp} fontSize={16} fontWeight={'bold'}>
          {t('Premium_')}
        </Typography>
      </div>
      <div className={classes.admiddlep}>
        <Typography
          className={classes.adLeft}
          fontSize={11}
          fontWeight={'bold'}>
          {t('유료적용_')}
        </Typography>
      </div>
      <div className={classes.admiddle}>
        <Typography className={classes.adPMar} fontSize={11}>
          {t('공고절차_')}
          {t('114114구인구직_')}
        </Typography>
        <Typography style={{padding: '0px 24px 5px'}} fontSize={11}>
          {t('글 공고번호_')}
        </Typography>
        <Typography style={{padding: '12px 24px 12px'}} fontSize={11}>
          {t('유료비용_')}220,000 {t('VAT 포함')}
        </Typography>
        <Typography style={{padding: '5px 24px 5px'}} fontSize={11}>
          {t('문의_')}032-813-7787 / 070-4277-7788
        </Typography>
        <Typography style={{padding: '0px 24px 10px'}} fontSize={11}>
          {t('유료접수_')}
          {t('114114구인구직')}
          &nbsp;
          <a href="http://pf.kakao.com/_dRDxmG">{t('채널추가')}</a>
        </Typography>
      </div>
      <div className={classes.adbtnp}>
        <Button onClick={handleClose} className={classes.adcbtn}>
          {t('닫기')}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.flexLayout}>
      <p className={classes.adbtn}>
        {t('Premium_')}
      </p>
      <p onClick={handleOpen} className={classes.adbtnSub}>
        {t('Premium_sub')}
      </p>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalBody}>
        {body}
      </Modal>
    </div>
  );
}
