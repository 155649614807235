import React, {useEffect, useState, useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {MobileWeb} from '../../../components/Mobile/Breakpoints';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {createSido, SIDO} from '~/features/Sido/slice';
import {createSigungu} from '~/features/Sigungu/slice';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  layout: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto',
    fontFamily: 'Noto Sans Bengali',
    fontSize: '12px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      margin: '0 auto',
    },
  },

  selectbar: {
    border: '1px solid #ccc',
    background: '#eee',
    padding: '0 4px',
    display: 'inline-flex',
    width: '970px',
    height: '47px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      height: '72px',
      background: '#E9F4FE',
      border: '0px',
      padding: '0px',
    },
  },

  selectbox: {
    width: '118px',
    height: '20px',
    float: 'left',
    listStyle: 'none',
    marginRight: '9px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '162px',
      height: '25px',
      marginRight: '9px',
    },
  },

  ptag: {
    display: 'flex',
    margin: '0 22px 0 27px',
    float: 'left',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  selectprop: {
    width: '100%',
    height: '20px',
    lineHeight: '20px',
    // margin: '1px 5px 0 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      height: '23px',
    },
  },

  searchform: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    margin: '13px 12px 14px auto',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  searchipt: {
    width: '109px',
    height: '20px',
    lineHeight: '23px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '0',
    float: 'left',
    margin: '0 -1px 0 0',
    fontSize: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  searchbtn: {
    width: '50px',
    height: '22px',
    float: 'left',
    border: '1px solid #ccc',
    background: '#ddd',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '11px',

    '&:hover': {
      background: 'none',
    },

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  selectDiv: {
    display: 'flex',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },
}));

interface ChildProps {
  loadSido?: any;
  loadSigg?: any;
  initSido?: string;
  initSigg?: string | null;
}

const SelectRegion = (props: ChildProps) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {initSido, initSigg} = props;

  const {sidoList} = useSelector((state: RootState) => state[SIDO]) ?? {};

  const [sido, setSido] = useState(`전국`);

  useEffect(() => {
    const parentSido = _.find(sidoList, function (o) {
      return `${o.code}` === initSido;
    })?.name;
    if (parentSido) {
      setSido(parentSido);
    } else {
      setSido('전국');
    }
  }, [sidoList, initSido]);

  useEffect(() => {
    const sigungu = _.find(sidoList, function (o) {
      return `${o.name}` === sido;
    })?.sigungus;
    if (sigungu) {
      if (initSigg) {
        const parentSgg = _.find(sigungu, function (o) {
          return `${o.code}` === initSigg;
        })?.name;
        if (parentSgg) {
          setChangeSgg(parentSgg);
        } else {
          setChangeSgg(`${t('시/군/구')}`);
        }
      } else {
        setChangeSgg(`${t('시/군/구')}`);
      }
    }
  }, [sidoList, initSigg, sido, t]);

  const sigungu = useMemo(() => {
    if (sido === '전국') {
      return <option>{t('시/군/구')}</option>;
    } else if (sido === '서울') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('강남구')}</option>
          <option>{t('강동구')}</option>
          <option>{t('강북구')}</option>
          <option>{t('강서구')}</option>
          <option>{t('관악구')}</option>
          <option>{t('광진구')}</option>
          <option>{t('구로구')}</option>
          <option>{t('금천구')}</option>
          <option>{t('노원구')}</option>
          <option>{t('도봉구')}</option>
          <option>{t('동대문구')}</option>
          <option>{t('동작구')}</option>
          <option>{t('마포구')}</option>
          <option>{t('서대문구')}</option>
          <option>{t('서초구')}</option>
          <option>{t('성동구')}</option>
          <option>{t('성북구')}</option>
          <option>{t('송파구')}</option>
          <option>{t('양천구')}</option>
          <option>{t('영등포구')}</option>
          <option>{t('용산구')}</option>
          <option>{t('은평구')}</option>
          <option>{t('종로구')}</option>
          <option>{t('중구')}</option>
          <option>{t('중랑구')}</option>
        </>
      );
    } else if (sido === '경기도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('가평군')}</option>
          <option>{t('고양시')}</option>
          <option>{t('과천시')}</option>
          <option>{t('광명시')}</option>
          <option>{t('광주시')}</option>
          <option>{t('구리시')}</option>
          <option>{t('군포시')}</option>
          <option>{t('김포시')}</option>
          <option>{t('남양주시')}</option>
          <option>{t('동두천시')}</option>
          <option>{t('부천시')}</option>
          <option>{t('성남시')}</option>
          <option>{t('수원시')}</option>
          <option>{t('시흥시')}</option>
          <option>{t('안산시')}</option>
          <option>{t('안성시')}</option>
          <option>{t('안양시')}</option>
          <option>{t('양주시')}</option>
          <option>{t('양평군')}</option>
          <option>{t('여주시')}</option>
          <option>{t('연천군')}</option>
          <option>{t('오산시')}</option>
          <option>{t('용인시')}</option>
          <option>{t('의왕시')}</option>
          <option>{t('의정부시')}</option>
          <option>{t('이천시')}</option>
          <option>{t('파주시')}</option>
          <option>{t('평택시')}</option>
          <option>{t('포천시')}</option>
          <option>{t('하남시')}</option>
          <option>{t('화성시')}</option>
        </>
      );
    } else if (sido === '인천') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('강화군')}</option>
          <option>{t('계양구')}</option>
          <option>{t('남동구')}</option>
          <option>{t('동구')}</option>
          <option>{t('미추홀구')}</option>
          <option>{t('부평구')}</option>
          <option>{t('서구')}</option>
          <option>{t('연수구')}</option>
          <option>{t('옹진군')}</option>
          <option>{t('중구')}</option>
        </>
      );
    } else if (sido === '부산') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('강서구')}</option>
          <option>{t('금정구')}</option>
          <option>{t('기장군')}</option>
          <option>{t('남구')}</option>
          <option>{t('동구')}</option>
          <option>{t('동래구')}</option>
          <option>{t('부산진구')}</option>
          <option>{t('북구')}</option>
          <option>{t('사상구')}</option>
          <option>{t('사하구')}</option>
          <option>{t('서구')}</option>
          <option>{t('수영구')}</option>
          <option>{t('연제구')}</option>
          <option>{t('영도구')}</option>
          <option>{t('중구')}</option>
          <option>{t('해운대구')}</option>
        </>
      );
    } else if (sido === '대구') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('남구')}</option>
          <option>{t('달서구')}</option>
          <option>{t('달성군')}</option>
          <option>{t('동구')}</option>
          <option>{t('북구')}</option>
          <option>{t('서구')}</option>
          <option>{t('수성구')}</option>
          <option>{t('중구')}</option>
        </>
      );
    } else if (sido === '대전') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('대덕구')}</option>
          <option>{t('동구')}</option>
          <option>{t('서구')}</option>
          <option>{t('유성구')}</option>
          <option>{t('중구')}</option>
        </>
      );
    } else if (sido === '광주') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('광산구')}</option>
          <option>{t('남구')}</option>
          <option>{t('동구')}</option>
          <option>{t('북구')}</option>
          <option>{t('서구')}</option>
        </>
      );
    } else if (sido === '세종') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('세종특별자치시')}</option>
        </>
      );
    } else if (sido === '울산') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('남구')}</option>
          <option>{t('동구')}</option>
          <option>{t('북구')}</option>
          <option>{t('울주군')}</option>
          <option>{t('중구')}</option>
        </>
      );
    } else if (sido === '강원도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('강릉시')}</option>
          <option>{t('고성군')}</option>
          <option>{t('동해시')}</option>
          <option>{t('삼척시')}</option>
          <option>{t('속초시')}</option>
          <option>{t('양구군')}</option>
          <option>{t('양양군')}</option>
          <option>{t('영월군')}</option>
          <option>{t('원주시')}</option>
          <option>{t('인제군')}</option>
          <option>{t('정선군')}</option>
          <option>{t('철원군')}</option>
          <option>{t('춘천시')}</option>
          <option>{t('태백시')}</option>
          <option>{t('평창군')}</option>
          <option>{t('홍천군')}</option>
          <option>{t('화천군')}</option>
          <option>{t('횡성군')}</option>
        </>
      );
    } else if (sido === '경상남도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('거제시')}</option>
          <option>{t('거창군')}</option>
          <option>{t('고성군')}</option>
          <option>{t('김해시')}</option>
          <option>{t('남해군')}</option>
          <option>{t('밀양시')}</option>
          <option>{t('사천시')}</option>
          <option>{t('산청군')}</option>
          <option>{t('양산시')}</option>
          <option>{t('의령군')}</option>
          <option>{t('진주시')}</option>
          <option>{t('창녕군')}</option>
          <option>{t('창원시')}</option>
          <option>{t('통영시')}</option>
          <option>{t('하동군')}</option>
          <option>{t('함안군')}</option>
          <option>{t('함양군')}</option>
          <option>{t('합천군')}</option>
        </>
      );
    } else if (sido === '경상북도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('경산시')}</option>
          <option>{t('경주시')}</option>
          <option>{t('고령군')}</option>
          <option>{t('구미시')}</option>
          <option>{t('군위군')}</option>
          <option>{t('김천시')}</option>
          <option>{t('문경시')}</option>
          <option>{t('봉화군')}</option>
          <option>{t('상주시')}</option>
          <option>{t('성주군')}</option>
          <option>{t('안동시')}</option>
          <option>{t('영덕군')}</option>
          <option>{t('영양군')}</option>
          <option>{t('영주시')}</option>
          <option>{t('영천시')}</option>
          <option>{t('예천군')}</option>
          <option>{t('울릉군')}</option>
          <option>{t('울진군')}</option>
          <option>{t('의성군')}</option>
          <option>{t('청도군')}</option>
          <option>{t('청송군')}</option>
          <option>{t('칠곡군')}</option>
          <option>{t('포항시')}</option>
        </>
      );
    } else if (sido === '전라남도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('강진군')}</option>
          <option>{t('고흥군')}</option>
          <option>{t('곡성군')}</option>
          <option>{t('광양시')}</option>
          <option>{t('구례군')}</option>
          <option>{t('나주시')}</option>
          <option>{t('담양군')}</option>
          <option>{t('목포시')}</option>
          <option>{t('무안군')}</option>
          <option>{t('보성군')}</option>
          <option>{t('순천시')}</option>
          <option>{t('신안군')}</option>
          <option>{t('여수시')}</option>
          <option>{t('영광군')}</option>
          <option>{t('영암군')}</option>
          <option>{t('완도군')}</option>
          <option>{t('장성군')}</option>
          <option>{t('장흥군')}</option>
          <option>{t('진도군')}</option>
          <option>{t('함평군')}</option>
          <option>{t('해남군')}</option>
          <option>{t('화순군')}</option>
        </>
      );
    } else if (sido === '전라북도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('고창군')}</option>
          <option>{t('군산시')}</option>
          <option>{t('김제시')}</option>
          <option>{t('남원시')}</option>
          <option>{t('무주군')}</option>
          <option>{t('부안군')}</option>
          <option>{t('순창군')}</option>
          <option>{t('완주군')}</option>
          <option>{t('익산시')}</option>
          <option>{t('임실군')}</option>
          <option>{t('장수군')}</option>
          <option>{t('전주시')}</option>
          <option>{t('정읍시')}</option>
          <option>{t('진안군')}</option>
        </>
      );
    } else if (sido === '충청남도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('계룡시')}</option>
          <option>{t('공주시')}</option>
          <option>{t('금산군')}</option>
          <option>{t('논산시')}</option>
          <option>{t('당진시')}</option>
          <option>{t('보령시')}</option>
          <option>{t('부여군')}</option>
          <option>{t('서산시')}</option>
          <option>{t('서천군')}</option>
          <option>{t('아산시')}</option>
          <option>{t('예산군')}</option>
          <option>{t('천안시')}</option>
          <option>{t('청양군')}</option>
          <option>{t('태안군')}</option>
          <option>{t('홍성군')}</option>
        </>
      );
    } else if (sido === '충청북도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('괴산군')}</option>
          <option>{t('단양군')}</option>
          <option>{t('보은군')}</option>
          <option>{t('영동군')}</option>
          <option>{t('옥천군')}</option>
          <option>{t('음성군')}</option>
          <option>{t('제천시')}</option>
          <option>{t('증평군')}</option>
          <option>{t('진천군')}</option>
          <option>{t('청주시')}</option>
          <option>{t('충주시')}</option>
        </>
      );
    } else if (sido === '제주도') {
      return (
        <>
          <option>{t('시/군/구')}</option>
          <option>{t('서귀포시')}</option>
          <option>{t('제주시')}</option>
        </>
      );
    } else return <option>{t('시/군/구')}</option>;
  }, [t, sido]);
  useEffect(() => {
    dispatch(createSido({id: 1}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(createSigungu());
  }, [dispatch]);

  const classes = useStyles();

  const [changeSgg, setChangeSgg] = useState(`${t('시/군/구')}`);

  // const sidos = useMemo(() => {
  //   return _.find(sidoList, function (o) {
  //     return `${o.name}` === sido;
  //   })?.id;
  // }, [sidoList, sido]);

  // const sidosCode = useMemo(() => {
  //   return _.find(sidoList, function (o) {
  //     return `${o.name}` === sido;
  //   })?.code;
  // }, [sidoList, sido]);

  const sidosBord = useMemo(() => {
    return _.find(sidoList, function (o) {
      return `${o.name}` === sido;
    })?.sigungus;
  }, [sidoList, sido]);

  // const sigungus = useMemo(() => {
  //   return _.find(sidosBord, function (o) {
  //     return `${o.name}` === changeSgg;
  //   })?.id;
  // }, [sidosBord, changeSgg]);

  // const siggCode = useMemo(() => {
  //   return _.find(sidosBord, function (o) {
  //     return `${o.name}` === changeSgg;
  //   })?.code;
  // }, [sidosBord, changeSgg]);

  // 왜 넣었는지 모름 0428
  // const handleSd = async () => {
  //   try {
  //     await axios
  //       .get(`${API_URI}/sidos?id=${sidos}`)
  //       .then(() => {})
  //       .catch(() => {});
  //   } catch (error) {}
  // };

  // const handleSgg = async () => {
  //   try {
  //     await axios
  //       .get(`${API_URI}/sigungus?id=${sigungus}`)
  //       .then(() => {})
  //       .catch(() => {});
  //   } catch (error) {}
  // };

  return (
    <div>
      <p className={classes.ptag}>{t('선택')}</p>
      <li className={classes.selectbox}>
        <select
          className={classes.selectprop}
          value={sido}
          // onClick={(e) => {
          //   // handleSd();
          //   props.loadSido(sidosCode);
          // }}
          onChange={(e) => {
            console.log(e.target.value);

            const sidoCode = _.find(sidoList, function (o) {
              return `${o.name}` === e.target.value;
            })?.code;

            // handleSd();
            props.loadSido(sidoCode);
            setSido(e.target.value);
            setChangeSgg(`${t('시/군/구')}`);
          }}>
          <option value="전국">{t('시/도')}</option>
          <option value="서울">{t('서울특별시')}</option>
          <option value="경기도">{t('경기도')}</option>
          <option value="인천">{t('인천광역시')}</option>
          <option value="강원도">{t('강원도')}</option>
          <option value="충청북도">{t('충청북도')}</option>
          <option value="충청남도">{t('충청남도')}</option>
          <option value="세종">{t('세종특별시')}</option>
          <option value="전라북도">{t('전라북도')}</option>
          <option value="전라남도">{t('전라남도')}</option>
          <option value="광주">{t('광주광역시')}</option>
          <option value="경상북도">{t('경상북도')}</option>
          <option value="경상남도">{t('경상남도')}</option>
          <option value="울산">{t('울산광역시')}</option>
          <option value="대구">{t('대구광역시')}</option>
          <option value="대전">{t('대전광역시')}</option>
          <option value="부산">{t('부산광역시')}</option>
          <option value="제주도">{t('제주특별시')}</option>
        </select>
      </li>
      {/* 시/구/군 */}
      <li className={classes.selectbox}>
        <select
          className={classes.selectprop}
          name="sigunguSelect"
          id="sigunguSelect"
          value={changeSgg}
          // onClick={() => {
          //   // handleSgg();
          //   props.loadSigg(siggCode);
          // }}
          onChange={(e) => {
            console.log(e.target.value);

            const siggCode = _.find(sidosBord, function (o) {
              return `${o.name}` === e.target.value;
            })?.code;

            // handleSgg();
            props.loadSigg(siggCode);
            setChangeSgg(e.target.value);
          }}>
          {sigungu}
        </select>
      </li>
    </div>
  );
};

export default SelectRegion;
