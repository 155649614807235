import {PayloadAction} from '@reduxjs/toolkit';
import {call, takeLatest, put} from 'redux-saga/effects';
import * as Api from '../../apis/Board';
import {
  createBoard,
  successLoadBoard,
  createBoardId,
  successLoadBoardId,
} from './slice';

function* handleGetBoard() {
  try {
    const res = yield call(Api.getBoard);
    const response = res.data;

    if (res.status >= 200 && res.status < 300) {
      yield put(successLoadBoard(response));
    }
  } catch (err) {}
}

function* handleGetBoardId({payload}: PayloadAction<{id: number}>) {
  try {
    const res = yield call(Api.getBoardId, payload.id);
    const response = res.data;

    if (res.status >= 200 && res.status < 300) {
      yield put(successLoadBoardId(response));
    }
  } catch (err) {}
}

export function* watchBoard() {
  yield takeLatest(createBoard, handleGetBoard);
  yield takeLatest(createBoardId, handleGetBoardId);
}
