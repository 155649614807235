import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import React from 'react';
export const ChinaIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_70:485)">
          <path
            d="M18.9632 38.0925C29.4769 38.0925 38 29.5695 38 19.0558C38 8.54208 29.4769 0.019043 18.9632 0.019043C8.44955 0.019043 -0.0734863 8.54208 -0.0734863 19.0558C-0.0734863 29.5695 8.44955 38.0925 18.9632 38.0925Z"
            fill="url(#paint0_linear_70:485)"
          />
          <path
            d="M37.2387 19.0557C37.2387 8.96294 29.056 0.780273 18.9632 0.780273C8.86914 0.780273 0.687744 8.96294 0.687744 19.0557C0.687744 29.1485 8.87041 37.3312 18.9632 37.3312C29.056 37.3312 37.2387 29.1498 37.2387 19.0557ZM22.7113 7.35301L23.9754 6.75767L23.3028 7.98254L24.2591 9.00094L22.8861 8.73874L22.2135 9.96361L22.0387 8.57661L20.6656 8.31441L21.9297 7.71907L21.7549 6.33207L22.7113 7.35301ZM13.039 14.3475L9.79508 11.9903L6.55114 14.3475L7.78994 10.5336L4.54474 8.17634H8.55501L9.79381 4.36241L11.0326 8.17634H15.0429L11.8002 10.5336L13.039 14.3475ZM17.466 3.16794L18.7529 3.71387L19.6687 2.65874L19.5471 4.05081L20.8341 4.59674L19.4724 4.91214L19.3508 6.30421L18.6301 5.10721L17.2684 5.42261L18.1842 4.36747L17.466 3.16794ZM19.5104 17.6459L19.4572 19.0431L18.6795 17.8815L17.3343 18.2615L18.1981 17.1633L17.4204 16.0018L18.7314 16.4844L19.5953 15.3862L19.5421 16.7833L20.8531 17.2659L19.5104 17.6459ZM23.7398 15.0771L22.577 14.3032L21.4813 15.1696L21.8588 13.8244L20.696 13.0505L22.0919 12.9935L22.4693 11.6483L22.9557 12.9593L24.3516 12.9023L23.2559 13.7699L23.7398 15.0771Z"
            fill="#DD2E26"
          />
          <path
            d="M18.6315 5.10467L19.3522 6.30294L19.4726 4.91087L20.8343 4.59547L19.5473 4.04954L19.6689 2.65747L18.7518 3.7126L17.4661 3.16794L18.1856 4.36494L17.2698 5.42007L18.6315 5.10467Z"
            fill="#FEDE00"
          />
          <path
            d="M20.6655 8.31566L22.0386 8.57786L22.2134 9.9636L22.886 8.73873L24.2591 9.00093L23.3027 7.98253L23.9753 6.75766L22.7112 7.35299L21.7549 6.33459L21.9297 7.72033L20.6655 8.31566Z"
            fill="#FEDE00"
          />
          <path
            d="M19.5964 15.3862L18.7326 16.4844L17.4216 16.0018L18.1981 17.1634L17.3342 18.2616L18.6794 17.8816L19.4558 19.0431L19.5103 17.646L20.8542 17.266L19.5432 16.7834L19.5964 15.3862Z"
            fill="#FEDE00"
          />
          <path
            d="M22.9533 12.9567L22.4682 11.647L22.0907 12.9922L20.6948 13.0492L21.8576 13.8244L21.4802 15.1696L22.5771 14.3032L23.7399 15.0771L23.2535 13.7674L24.3504 12.8997L22.9533 12.9567Z"
            fill="#FEDE00"
          />
          <path
            d="M11.0338 8.17635L9.79501 4.36243L8.55494 8.17635H4.54468L7.78988 10.5336L6.54981 14.3475L9.79501 11.9903L13.0389 14.3475L11.8001 10.5336L15.0441 8.17635H11.0338Z"
            fill="#FEDE00"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_70:485"
            x1="28.4831"
            y1="35.5453"
            x2="9.44286"
            y2="2.5667"
            gradientUnits="userSpaceOnUse">
            <stop offset="0.005618" stopColor="#CFCFCE" />
            <stop offset="0.3596" stopColor="#9C9C9B" />
            <stop offset="0.5427" stopColor="#A9A9A9" />
            <stop offset="0.6517" stopColor="#B3B3B3" />
            <stop offset="0.8652" stopColor="#B5B5B5" />
            <stop offset="1" stopColor="#DBDADA" />
          </linearGradient>
          <clipPath id="clip0_70:485">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
