import {makeStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {MobileWeb} from '~/components/Mobile/Breakpoints';
import {setLang, LangCode} from '~/features/Login/slice';
import {useTranslation} from 'react-i18next';
import Header from '~/components/Header';
import {Tabs} from '@material-ui/core';
import Tab from '@mui/material/Tab';
import Footer from '~/components/Footer';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import {API_URI} from '~/api/apiServer';
import {
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // Middle
  listul: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto ',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 360,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0',
      margin: '0 auto',
      background: '#FFF',
      justifyContent: 'center',
    },
  },

  listli: {
    padding: '16px, 0 0 0',
    // float: 'left',
    listStyle: 'none',
    '.MuiTabs-indicator': {
      display: 'none',
    },
  },

  listlink: {
    padding: '0px 15px !important',
    fontSize: '12px!important',
    color: '#333!important',
    float: 'left',
    opacity: '1!important',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '0px 12px!important',
      fontWeight: '600!important',
    },
  },

  topad: {
    maxWidth: 980,
    width: '100%',
    margin: '0 auto 105px',
    fontSize: '12px',
    padding: '0px 15px 15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      padding: '0',
      margin: '0 auto 35px',
    },
  },

  mobileFixed: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      top: '0',
    },
  },

  test: {
    display: 'none',
  },

  selected: {
    color: '#ff3900 !important',
  },

  topPage: {
    maxWidth: 980,
    width: '100%',
    left: '230px',
    borderTop: '0.5px solid #eee',
    boxSizing: 'border-box',
    fontFamily: 'Noto Sans Bengali',
    fontSize: '13px',
    margin: '0 auto',
    padding: '15px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      borderTop: '0',
      borderBottom: '0',
      padding: '18px 0 10px 5px',
    },
  },

  heading: {
    fontSize: theme.typography.pxToRem(15),
  },

  accoHeader: {
    padding: '12px 16px',
    marginBottom: 8,
  },

  accContent: {
    borderTop: '0.5px solid #eee',
    background: '#f8f9fb',
    display: 'flex',
    flexDirection: 'column',
  },

  tabContent: {
    fontSize: '16px !important',
  },
  //

  buttonProgress: {
    color: 'gray',
  },

  tabImageContent:{
    display: 'block',
    width: '100%',
    height: 'auto'
  }
}));

interface IState {
  mode?: number;
}

const Feedback = () => {
  const {t} = useTranslation();

  const classes = useStyles();
  const history = useHistory();

  const [value, setValue] = React.useState(4);
  const [headerLang, setHeaderLang] = useState<LangCode>();
  const [expanded, setExpanded] = useState<string | false>(false);

  // 번역
  useEffect(() => {
    if (headerLang !== undefined) {
      setLang(headerLang);
    }
  }, [headerLang]);

  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setValue(value);
  };

  const expandChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [getDataFromBackend, setDataFromBackend] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const getDataToBackend = async ()=> {
    try{
      await axios.get(`${API_URI}/posts?board=5`)
        .then(response=>{
          setDataFromBackend(response.data);
          setLoading(false);
        });
    }catch(e){
      setLoading(false);
    }
  }

  useEffect(() => {
    getDataToBackend();
  },[]);

  // const splitSentense = (sentence: String) => {
  //   const formatted = sentence.split('<br/>');
  //   return formatted;
  // }

  const getImageUrl = (fileInfo: any) => {
    const files: any[] = [];
    if(fileInfo.length !== 0){
      fileInfo.map((information: any) => {
        files.push(information.url);
        return information;
      });
    }
    return files;
  }

  return (
    <div
      style={{
        marginBottom: 80,
      }}>
      <div className={classes.mobileFixed}>
        <Header
          langProp={(headerLang: LangCode) => {
            setHeaderLang(headerLang);
          }}
        />
        <div className={classes.listul}>
          <Tabs
            variant="scrollable"
            scrollButtons="on"
            classes={{root: classes.listli, indicator: classes.test}}
            value={value}
            onChange={handleChange}>
            <Tab
              disableRipple
              classes={{root: classes.listlink, selected: classes.selected}}
              label={t('구인공고')}
              onClick={() => {
                history.push(`/info?page=1&boardId=1`);
              }}
            />
            <Tab
              disableRipple
              classes={{root: classes.listlink, selected: classes.selected}}
              label={t('구직공고')}
              onClick={() => {
                history.push(`/info?page=1&boardId=2`, {mode: 2});
              }}
            />
            <Tab
              disableRipple
              classes={{root: classes.listlink, selected: classes.selected}}
              label={t('중고나라')}
              onClick={() => {
                history.push('/community', {mode: 3});
              }}
            />
            <Tab
              disableRipple
              classes={{root: classes.listlink, selected: classes.selected}}
              label={t('부동산정보')}
              onClick={() => {
                history.push('/community', {mode: 4});
              }}
            />
            <Tab
              disableRipple
              classes={{root: classes.listlink, selected: classes.selected}}
              label={t('공지사항')}
              onClick={() => {
                history.push('/feedback', {mode: 5});
              }}
            />
          </Tabs>
        </div>
      </div>

      <div className={classes.topad}>
        <div className={classes.topPage}>
          <div>
            <div className={classes.accoHeader}>
              <Typography
                className={classes.heading}
                style={{fontWeight: 'bold'}}>
                {t('공지사항_t')}
              </Typography>
            </div>
            { loading ? (
              <CircularProgress size={24} className={classes.buttonProgress} />
            ) : (
              <div>
              {getDataFromBackend.map((item,index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={expandChange(`panel${index}`)}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <p className={classes.heading}>
                      {item.title}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accContent}>
                    {getImageUrl(item.files).map((fileUrl, index) => (
                      <img src={fileUrl} className={classes.tabImageContent} key={index} alt="114114구인구직 이미지 1"/>
                    ))}
                    <p className={classes.tabContent} key={index} style={{whiteSpace:'pre-line'}}>
                        {item.content}
                      </p>
                    {/* {splitSentense(item.content).map((sentences, index) => (
                      <p className={classes.tabContent} key={index} style={{whiteSpace:'pre-line'}}>
                        {sentences}
                      </p>
                    ))} */}
                  </AccordionDetails>
                </Accordion>
              ))}
              </div>
            )}
            {/* <Accordion
              expanded={expanded === 'panel1'}
              onChange={expandChange('panel1')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('최저시급 준수_')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  {t('최저시급은_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('최저 월급_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('최저 연봉_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('시간당 근로자에게_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('그밖에 우리가 연봉을_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('6가지의 공제 항목_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('각항목의 공제되는_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('국민연금_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('건강보험_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('장기요양보험_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('고용보험_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('소득세_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('지방소득세_')}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={expandChange('panel2')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('주휴수당_')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  {t('주휴수당은 무엇일까요?')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('주휴수당이란_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('다시 말해 주휴일에는_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('월급을 받는 근로자의_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('그렇다면 주휴수당 조건을_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('주일 근로시간이 15시간_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('1주일간 결근 없이_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('근로계약서상 근로일을 개근')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('조퇴나 결근은 주휴수당에_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('다음주 근로가 예상되어야_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('월~금요일 근무후 차주_')}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={expandChange('panel3')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('연차수당_')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  {t('사용자는 총 근로_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('2021년 01월 01일_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('사용자는 1년간 출근율이_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('이후 근속연수가 홀수로_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('예를 들어 근속연수가_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('근로자가 업무상의 부상_')}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel4'}
              onChange={expandChange('panel4')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('휴게시간 규정_')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  {t('휴게시간은 근로시간 기준_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('일반적으로 근로자가 1일_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('물론 휴게 시간_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('하지만 모든 사업장이_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('먼저 농림사업에 종사하는_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('그 밖에 대통령_')}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel5'}
              onChange={expandChange('panel5')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('퇴직금 지급 요건_')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  {t('고용주는 퇴직하는 근로자에게_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('근로자 퇴직급여 보장법_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('“평균임금”이란_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('<퇴직금 산정 공식>')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('퇴직금 산정 공식은_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('퇴직금_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('고용주는 근로자가 퇴직한_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('위에 따른 퇴직금은_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('근로자 퇴직급여 보장법_2')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('※ 다만, 다음의 경우에는_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('근로자 퇴직급여 보장법_3')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('근로자가 55세 이후에_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('급여가 고용노동부 장관이_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('출입국관리법 시행령_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('다른 법령에서 급여의_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('급여에서 일부를 공제한_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('근로자가 위에 따라_')}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel6'}
              onChange={expandChange('panel6')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('퇴사시 인수인계_')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  {t('근로기준법 및 타노동관계_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('인수인계라는_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('보통 회사들은 통상_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('근로자의 퇴직 자체를_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('물론 노사 간에_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('퇴직은 근로자와 사용자의_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('결론적으로 퇴사 통보_')}
                </Typography>
                <Typography className={classes.tabContent}>
                  {t('그리고 퇴사 통보는_')}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel7'}
              onChange={expandChange('panel7')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('분쟁시 해결방안_')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  {t('가급적 사용자와 근로자_')}
                </Typography>
                <br />
                <Typography className={classes.tabContent}>
                  {t('허나 실질적으로는 그런_')}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel8'}
              onChange={expandChange('panel8')}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {t('별도 문의사항 메일주소')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accContent}>
                <Typography className={classes.tabContent}>
                  winnercm@naver.com
                </Typography>
              </AccordionDetails>
            </Accordion> */}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Feedback;
