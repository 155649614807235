import axios from 'axios';
import {API_URI} from '~/api/apiServer';

export const getBoard = () => {
  return axios.get(`${API_URI}/boards?_limit=10000`);
};

export const getBoardId = (id: number) => {
  return axios.get(`${API_URI}/boards?_limit=10000&id=${id}`);
};
