import React from 'react';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {MobileWeb} from './Mobile/Breakpoints';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@material-ui/core';
import logo from '../image/winner_logo.webp';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  // Footer
  footer: {
    margin: '0 auto',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      justifyContent: 'center',
    },
  },

  logofooter: {
    fontFamily: 'Times New Roman, Times serif',
    fontSize: '30px',
    fontWeight: 'normal',
    textDecorationLine: 'none',
    color: '#333',
    marginRight: '30px',
    display: 'flex',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginRight: '0px',
      fontSize: '11px',
    },
  },

  logoTitle: {
    fontSize: '25px',
    fontWeight: 'normal',
    textDecorationLine: 'none',
    color: '#333',
    margin: '0',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginRight: '0px',
      fontSize: '8px',
      padding: '0',
    },
  },

  logoName: {
    color: '#FF3900',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      color: '#333',
      alignSelf: 'center',
    },
  },

  footbar: {
    width: '100%',
    bottom: '0%',
    background: '#daebf0',
    borderTop: '1px solid #ccc',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      background: '#daebf0',
      height: '80px',
      width: '100%',
      padding: '0',
      borderTop: 'none',
      position: 'fixed',
      textAlign: 'center',
      gap: 12,
    },
  },

  footbarul: {
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    maxWidth: 980,
    width: '100%',
    paddingLeft: 50,

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      minWidth: 360,
      textAlign: 'center',
      justifyContent: 'center',
      paddingLeft: 0,
    },
  },

  footbarli: {
    display: 'inline',
    padding: '12px 30px 6px 0',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '10px 10px 10px 0px',
    },
  },

  footerside: {
    font: 'Noto Sans KR, sans-serif',
    fontSize: '12px',
    textDecoration: 'none',
    color: '#333',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      font: 'Noto Sans KR, sans-serif',
      fontSize: '11px',
      textDecoration: 'none',
      color: '#333',
    },
  },

  footbarliMobile: {
    display: 'none',
    padding: '12px 30px 6px 0',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '10px 10px 6px 0px',
      display: 'inline',
    },
  },

  footbarliPC: {
    padding: '0px 0px 8px 0px',
    listStyle: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '10px 10px 10px 0px',
      display: 'none',
    },
  },

  totalDiv: {
    fontWeight: 600,
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      alignSelf: 'center',
    },
  },

  onlyPC: {
    paddingLeft: 50,
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      display: 'none',
    },
  },

  logoImg: {
    width: 60,
    height: 60,
    margin: '8px 2px 0px 2px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: 50,
      height: 50,
      margin: '6px 4px 0px 4px',
    },
  },

  boldSerif: {
    fontFamily: "Noto Serif KR",
    fontWeight: 700
  },

  footerInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },

  footSmallText: {
    fontSize: 8,
    marginRight: '8px',
    marginBlockStart: 2,
    marginBlockEnd: 2,
  },

  footBindText: {
    lineHeight: 1.2,
    display: 'block',
    textAlign: 'left',
  },
  
  footBindLight: {
    lineHeight: 1,
    display: 'block',
    fontSize: 8,
    textAlign: 'left'
  },

  siteFooterLogo: {
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    fontSize: 8,
  },
  
  morePadding: {
    padding: '8px 4px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      padding: '4px 0px',
    },
  },

  footBindSpan: {
    display: 'inline-block',
    marginRight: '8px',
    [theme.breakpoints.down(MobileWeb.mobile)]: {
      marginRight: '4px'
    },
  },

  footerSmallLink:{
    textDecoration: 'none',
    color: 'inherit',
  }
  

}));

const Footer = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  const theme = useTheme() as Theme;
  // const mobile = useMediaQuery(theme.breakpoints.down(426));
  const tab = useMediaQuery(theme.breakpoints.down(MobileWeb.mobile));

  return (
    <div className={classes.footer}>
      <div className={classes.footbar}>
        <div className={classes.morePadding}>
          <img src={logo} alt="logo" className={classes.logoImg} />
        </div>
        <div className={clsx(classes.footerInfo, classes.morePadding)}>
          {tab ? (
            <div></div>
          ) : (
            <Link to="/" className={classes.logofooter}>
              <p className={`${classes.logoTitle} ${classes.boldSerif}`}><span className='orange_highlight'>114</span>114</p>
              <p className={`${classes.logoTitle} ${classes.boldSerif}`}>&nbsp;{t('구인구직_')}.com</p>
            </Link>
          )}
          
          <div className={classes.footBindLight}>
            <span className={classes.footBindSpan}>{t('위너커머스 주식회사')}</span>
            <span className={classes.footBindSpan}>
              {t('인천광역시 연수구 송도미래로_2')}
            </span>
          </div>

          <div className={classes.footBindText}>
            <p className={classes.footSmallText}>
              {t('사업자등록증')} : 310-81-34901&nbsp;|&nbsp;
              {t('직업정보제공사업')} : J1500020230004&nbsp;|&nbsp;
              {t('통신판매업')} :{t('2023-인천연수구-0712 호')}&nbsp;|&nbsp;
              <span style={{color:'red'}}>
                {t('카카오채널')} : 114114{t('구인구직')}
                <a href="http://pf.kakao.com/_dRDxmG" style={{color:'red'}}>{t('채널추가')}</a>
              </span>
            </p>
          </div>

          <div className={classes.footBindLight}>
            <span className={classes.footBindSpan}>032-813-7787 / 070-4277-7788</span>
            <span className={classes.footBindSpan}>
              {t('이메일')} :{' '}
              <a href="mailto:winnercm@naver.com">winnercm@naver.com</a>
            </span>
            <span className={classes.footBindSpan}>
              <a href="./privacy" className={classes.footerSmallLink}>개인정보처리방침</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
