import {takeLatest, call, put} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {login, successLogin} from './slice';
import {LoginType} from '~/types';
import api from '~/api';

function* handleLogin({payload}: PayloadAction<LoginType>) {
  try {
    const resp = yield call(api.login, payload);
    yield put(successLogin(resp.data));
  } catch (err) {}
}

export function* watchLogin() {
  yield takeLatest(login, handleLogin);
}
