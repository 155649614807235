import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {MobileWeb} from '../Mobile/Breakpoints';
import {useParams} from 'react-router-dom';
import {API_URI} from '~/api/apiServer';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {VERIFY} from '~/features/Verify/slice';
import {loadPost} from '~/features/Post/slice';
import NewitemModal from '../Newitemmodal';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  adbtn: {
    fontFamily: 'Noto Sans Bengali',
    fontSize: '14px',
    textDecorationLine: 'none',
    minWidth: '94px',
    height: '25px',
    background: '#63C5DA',
    borderRadius: '0',
    // border: '1px solid #979696',
    color: '#fff',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      // color: '#2196F3',
      // background: '#EDF7FE',
      width: '45px',
      height: '30px',
      fontSize: '13px',
    },
    [theme.breakpoints.down(422)]: {
      minWidth: '70px',
    },
  },

  rePage: {
    position: 'absolute',
    width: '508px',
    height: '319px',
    background: '#fff',
    border: '1px solid #FF7842',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontSize: '11px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '265px',
    },
  },

  reTop: {
    position: 'relative',
    background: '#DCD8D8',
    height: '45px',
    width: '100%',
    margin: '0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },
  topP: {
    fontSize: '14px',
    fontFamily: 'Noto Sans Bengali',
    margin: '0',

    padding: '14px 17px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  admiddlep: {
    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adbtnp: {
    width: '94px',
    height: '25px',
    border: '1 solid #ccc',
    lineHeight: 20,
    display: 'flex',
    padding: '0 38px 20px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adcbtn: {
    position: 'relative',
    background: '#F0EFEF',
    borderRadius: '0',
    fontSize: '11px',
    width: '94px',
    height: '25px',
    border: '1px solid #979696',
    padding: '2px 21px',
    margin: '0 4px 0 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  adLeft: {
    marginLeft: '24px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  middletext: {
    width: '466px',
    height: '181px',
    padding: '0',
    margin: '17px 18px 20px 17px',

    [theme.breakpoints.down(MobileWeb.mobile)]: {
      width: '230px',
      margin: '17px',
    },
  },

  btnP: {
    width: '52px',
    height: '21px',
    flex: 'none',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },

  btnM: {
    alignSelf: 'center',
    margin: '0 5px 0 0',

    [theme.breakpoints.down(MobileWeb.mobile)]: {},
  },
}));

export default function Report() {
  const {t} = useTranslation();
  const {id} = useParams<{id: string}>();
  const {jwt} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const {user} = useSelector((state: RootState) => state[VERIFY]) ?? {};
  const [openModal, setOpenModal] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadPost());
  }, [dispatch]);

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [postContent, setPostContent] = useState(`
    ${t('신고 게시글 번호')} : ${id}
    ${t('신고 내용')} : 
  `);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reportButton = async () => {
    try {
      await axios
        .post(
          `${API_URI}/posts/`,
          {
            title: `신고합니다`,
            sido_code: '114',
            sgg_code: '114',
            content: `${postContent}
            신고자 전화번호 : ${user?.username?.substring(
              0,
              3,
            )}-${user?.username?.substring(3, 7)}-${user?.username?.substring(
              7,
            )}
            `,
            published_at: null,
          },
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          },
        )
        .then(() => {
          window.alert(t('신고 접수가 완료되었습니다.'));
        })
        .catch(() => {});
    } catch (error) {}
  };

  const handleReport = () => {
    reportButton();
    handleClose();
  };

  const body = (
    <div style={modalStyle} className={classes.rePage}>
      <div className={classes.reTop}>
        <p className={classes.topP}>{t('신고내용을 입력해주세요.')}</p>
      </div>
      <div className={classes.admiddlep}>
        <textarea
          className={classes.middletext}
          aria-multiline="true"
          style={{whiteSpace: 'pre-wrap'}}
          id="keyword"
          name="keyword"
          rows={11}
          value={postContent}
          onChange={(e) => {
            setPostContent(e.target.value);
          }}
        />
      </div>
      <div className={classes.adbtnp}>
        <Button
          onClick={() => {
            user ? handleReport() : setOpenModal(true);
          }}
          className={classes.adcbtn}>
          <p className={classes.btnP}>{t('등록')}</p>
        </Button>
        <Button onClick={handleClose} className={classes.adcbtn}>
          <p className={classes.btnP}>{t('취소')}</p>
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.btnM}>
      <Button onClick={handleOpen} className={classes.adbtn}>
        {t('신고')}
      </Button>
      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        {body}
      </Modal>
      <div style={{display: 'none'}}>
        <NewitemModal open={openModal} onClose={() => setOpenModal(false)} />
      </div>
    </div>
  );
}
