import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FileType} from '~/types';
import {User} from '~/types';

export interface IPostBoard {
  id: number;
  name?: string;
}

export interface IPost {
  id?: string;
  title: string;
  title_ch: string;
  title_vi: string;
  title_ru: string;
  sido_code: string;
  sgg_code: string;
  content: string;
  content_ch: string;
  content_vi: string;
  content_ru: string;
  cat1: string;
  cat2: string;
  board: IPostBoard;
  published_at?: string;
  created_at?: string;
  updated_at?: string;
  files?: FileType[];
  user?: User;
}

interface IState {
  type?: string;
  postList?: IPost[];
  loading?: boolean;
}
let initialState: IState = {
  // state의 초기 값
  loading: false,
  postList: undefined,
  // idList: undefined,
};
const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    postSlice: (state, _action: PayloadAction<IPost>) => {},
    loadPost: (state) => {
      state.loading = true;
    },
    successLoadPost: (state, {payload}: PayloadAction<IPost[]>) => {
      state.loading = false;
      state.postList = payload;
      // state.idList = payload;
    },
    inputAd: (state) => {
      state.loading = true;
    },
    successInputAd: (state, {payload}: PayloadAction<IPost[]>) => {
      state.loading = false;
      state.postList = payload;
    },
    // getPost: (state) => {
    //   state.loading = true;
    // },
    // successGetPost: (state, {payload}: PayloadAction<IPost[]>) => {
    //   state.loading = false;
    //   state.postList = payload;
    // },
  },
});
export const {
  postSlice,
  loadPost,
  successLoadPost,
  inputAd,
  successInputAd,
  // getPost,
  // successGetPost,
} = slice.actions;
export const POST = slice.name;
export default slice.reducer;
