import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LoginType, User, LoginResponse, JwtToken} from '~/types';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

export type LangCode = 'ko' | 'ch' | 'vi' | 'ru';

interface State {
  loading: boolean;
  authorized: boolean;
  user?: User;
  token?: string;
  lang: LangCode;
}

let initialState: State = {
  loading: false,
  authorized: false,
  user: undefined,
  token: undefined,
  lang: 'ko',
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, _action: PayloadAction<LoginType>) => {
      state.loading = true;
    },
    successLogin: (state, action: PayloadAction<LoginResponse>) => {
      state.loading = false;
      state.authorized = true;
      state.user = action.payload.user;
      state.token = action.payload.jwt;
    },
    validToken: (state) => {
      if (state.token) {
        const token = jwtDecode<JwtToken>(state.token);
        const exp = moment.unix(token.exp);
        if (exp > moment()) {
          state.authorized = true;
        }
      }
    },
    setLang: (state, {payload}: PayloadAction<LangCode>) => {
      state.lang = payload;
    },
  },
});

export const {login, successLogin, validToken, setLang} = slice.actions;
export const LOGIN = slice.name;
export default slice.reducer;
