import {useMemo} from 'react';
import {getPagination} from '~/utils/getPagenation';

interface usePaginationProps {
  totalPage: number;
  currentPage: number;
  maxPageCount: number;
}

const usePagination = ({
  totalPage,
  currentPage,
  maxPageCount,
}: usePaginationProps) => {
  const pageList = useMemo(() => {
    return getPagination(totalPage, currentPage, maxPageCount);
  }, [totalPage, currentPage, maxPageCount]);

  return {pageList};
};

export default usePagination;
