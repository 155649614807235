import axios from 'axios';
import {API_URI} from '~/api/apiServer';

export const loadSido = () => {
  return axios.post(`${API_URI}/sidos`);
};

export const getSido = () => {
  return axios.get(`${API_URI}/sidos?_limit=10000`);
};
