import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface BPosts {
  id?: number;
  name?: string;
  title?: string;
  sido_code?: string;
  sgg_code?: string;
  board?: number;
  published_at?: string;
  created_at?: string;
  updated_at?: string;
  cat1?: string;
  cat2?: string;
}
export interface IBoard {
  id?: number;
  name?: string;
  posts?: BPosts[];
  published_at?: string;
  created_at?: string;
  updated_at?: string;
  board?: BPosts[];
  content?: string;
  sgg_code?: string;
  sido_code?: string;
  title?: string;
}

interface IState {
  boardList?: IBoard[];
  loading?: boolean;
}

let initialState: IState = {
  loading: false,
  boardList: undefined,
};

const slice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    createBoard: (state, {payload}: PayloadAction<{id: number}>) => {
      state.loading = true;
    },
    successLoadBoard: (state, {payload}: PayloadAction<IBoard[]>) => {
      state.loading = false;
      state.boardList = payload;
    },
    createBoardId: (state, action: PayloadAction<{id: number}>) => {
      state.loading = true;
    },
    successLoadBoardId: (state, {payload}: PayloadAction<IBoard[]>) => {
      state.loading = false;
      state.boardList = payload;
    },
  },
});
export const {
  createBoard,
  successLoadBoard,
  createBoardId,
  successLoadBoardId,
} = slice.actions;
export const BOARD = slice.name;
export default slice.reducer;
